import { createSlice } from '@reduxjs/toolkit';
// import { result } from 'lodash-es';
import * as dashboardsApiService from 'src/services/dashboards';

// Redux Actions
const INITIAL_STATE = {
    loading: false,
    diariesByShop: {},
    kpisById: {},
};

const slice = createSlice({
    name: 'dashboards',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        KPI_BY_ID(state, action) {
            state.kpisById = {
                ...state.kpisById,
                ...action.payload,
            };
        },
        DIARY(state, action) {
            const { shopId, diary, data, date } = action.payload;
            let newShopDiaries = {};
            if (state.diariesByShop && state.diariesByShop[shopId])
                newShopDiaries = { ...state.diariesByShop[shopId] };
            newShopDiaries.date = date;
            newShopDiaries[diary] = data;
            state.diariesByShop[shopId] = newShopDiaries;
            state.loading = false;
        },
        KPI_BY_ID_SUMMARIZED(state, action) {
            state.kpisById[action.payload.kpiId].summarized =
                action.payload.summarized;
        },
        LOADING_CANCEL(state, action) {
            state.loading = false;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators

export const getKpi = (kpiId, params = {}) => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING());
    const {
        shops: { currentShop },
        dashboards: { diariesByShop },
    } = getState();
    const { timeRange = {}, categoriesFilter } = params;
    const kpiData = await dashboardsApiService.getKpi(kpiId);
    // Guardar la información del KPI
    dispatch(slice.actions.KPI_BY_ID(kpiData));
    const { diaryData = null } = kpiData[kpiId];

    let diary = null;
    const {
        dateStart = new Date('2000-01-01'),
        dateEnd = new Date(),
    } = timeRange;
    const shopDiaries = { ...diariesByShop[currentShop] } || {};

    // Si no está el diario del KPI en el state, recuperarlo
    // const { [diaryData]: prevDiary = null } = shopDiaries;
    if (!shopDiaries[diaryData]) {
        const { results, date } = await dashboardsApiService.getShopDiary({
            shopId: currentShop,
            timeRange: {
                dateStart,
                dateEnd,
            },
            diary: diaryData,
        });
        diary = results;
        // Guardar el diario del KPI
        await dispatch(
            slice.actions.DIARY({
                shopId: currentShop,
                diary: diaryData,
                date,
                data: diary,
            }),
        );
    } else {
        diary = shopDiaries[diaryData];
    }
    // Calcular el KPI
    const summarized = await dashboardsApiService.processKpi({
        diary,
        date: { dateStart, dateEnd },
        kpiData: kpiData[kpiId],
        categoriesFilter,
    });
    dispatch(slice.actions.KPI_BY_ID_SUMMARIZED({ kpiId, summarized }));
    dispatch(slice.actions.LOADING_CANCEL());
};

// export const getShopDiary = (timeRange = {}) => async (
//     dispatch,
//     getState,
// ) => {
//     dispatch(slice.actions.LOADING());
//     const {
//         shops: { currentShop },
//         dashboards: { occupationDiaryByShop },
//     } = getState();
//     let occupationDiary = null;
//     const {
//         dateStart = new Date('2000-01-01'),
//         dateEnd = new Date(),
//     } = timeRange;
//     if (!occupationDiaryByShop[currentShop]) {
//         const {
//             results,
//             date,
//         } = await dashboardsApiService.getShopDiary(currentShop, {
//             dateStart,
//             dateEnd,
//         });
//         occupationDiary = results;
//         // console.log("SLICES RESULTS DIARY", occupationDiary)
//         dispatch(
//             slice.actions.DIARY({
//                 [currentShop]: { occupationDiary, date },
//             }),
//         );
//     }
//     return;
// };

// export const processKpi = (kpi) => async (dispatch, getState) => {
//     // dispatch(slice.actions.LOADING());
//     const {
//         shops: { currentShop },
//         dashboards: { occupationDiaryByShop },
//     } = getState();
//     let occupationDiary = null;
//     const { dateStart = new Date('2000-01-01'), dateEnd = new Date() } = timeRange;
//     if (!occupationDiaryByShop[currentShop]) {
//         const {
//             results,
//             date,
//         } = await dashboardsApiService.getShopDiary(currentShop, { dateStart, dateEnd });
//         occupationDiary = results;
//         console.log("SLICES RESULTS DIARY", occupationDiary)
//         dispatch(
//             slice.actions.DIARY({
//                 [currentShop]: { occupationDiary, date },
//             }),
//         );
//     }
//     const results = dashboardsApiService.processKpi(kpi);
//     return;
// };
