import firebase from 'src/lib/firebase';
import { getRef as getShopRef } from './shops';

const db = firebase.firestore();
const storage = firebase.storage();

const serializeInvoice = async (id, data) => {
    const {
        date: dateInvoice = null,
        times = {},
        documentPath = null,
        invoiceId,
        invoiceNumber,
        metadata: { holder: holderRef = null, order: orderRef = null },
        profile: profileRef = null,
        shop: shopRef = null,
        total,
    } = data;
    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = new Date(times[keyTime].toDate())),
    );

    let holder = null;
    if (holderRef && typeof holderRef !== 'string') {
        const holderDoc = await holderRef.get();
        let { name, docNumber, status } = holderDoc.data();
        if (typeof name !== 'string') {
            name = `${name.first || ''}${name.first && name.last ? ' ' : ''}${
                name.last
            }`;
        }
        holder = {
            name,
            docNumber: docNumber.value,
            status,
            path: holderRef.path,
        };
    }

    let downloadUrl = null;
    try {
        downloadUrl = documentPath
            ? await storage.ref(documentPath).getDownloadURL()
            : null;
    } catch (error) {
        console.log('Error getting invoice path');
    }

    return {
        id,
        date:
            dateInvoice && dateInvoice.toDate
                ? dateInvoice.toDate()
                : newTimes.createdAt,
        times: newTimes,
        documentPath: downloadUrl,
        invoiceId,
        invoiceNumber,
        holder,
        metadata: {
            orderId: orderRef && orderRef.id ? orderRef.id : orderRef,
        },
        profileId: profileRef && profileRef.id ? profileRef.id : profileRef,
        shopId: shopRef && shopRef.id ? shopRef.id : shopRef,
        total,
    };
};

export const process = async (document) => {
    if (!document.exists) return null;
    const invoice = await serializeInvoice(document.ref.id, document.data());
    return invoice;
};

const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getCollectionRef = () => db.collection('invoices');

export const getRef = (id) => getCollectionRef().doc(id);

export const getAll = async (shopId = null) => {
    if (!shopId) return {};
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .orderBy('invoiceNumber', 'desc')
        .get();
    return processAll(docs, process);
};

export const getById = async (invoiceId = null) => {
    const doc = await getCollectionRef()
        .doc(invoiceId)
        .get();
    const invoice = await serializeInvoice(doc.ref.id, doc.data());
    return [invoice];
};

export const syncWithZoho = async (invoiceId = null) => {
    if (!invoiceId) return false;
    const invoiceRef = getRef(invoiceId);
    await invoiceRef.update({
        documentPath: firebase.firestore.FieldValue.delete(),
    });
};

export const getInvoicesByProfile = async (shopId, profileId) => {
    if (!shopId) return [];
    const userRef = db.collection('profiles').doc(profileId);
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .where('profile', '==', userRef)
        .orderBy('times.createdAt', 'desc')
        .get();
    return processAll(docs, process);
};

export const getByCreationPag = async (shopId, offset = null) => {
    if (!shopId) return [];
    const shopRef = getShopRef(shopId);
    let query = getCollectionRef()
        .where('shop', '==', shopRef)
        // .where('status', 'in', ['accepted', 'completed', 'executing', 'draft'])
        .orderBy('date', 'desc');
    const { docs: totalInvoces } = await query.get();
    // if (offset) query = query.startAfter(offset);
    if (offset) query = query.startAfter(offset);
    const { docs } = await query.limit(50).get();
    let newOffset = null;
    if (docs.length) {
        newOffset = docs[docs.length - 1].data().date.toDate();
    }
    const allPromises = docs.map((doc) => process(doc));
    const invoices = await Promise.all(allPromises);
    return { invoices, offset: newOffset, total: totalInvoces.length };
};
