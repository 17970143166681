import { createSlice } from '@reduxjs/toolkit';
import * as contractsApiService from 'src/services/contracts';

// Redux Actions

const INITIAL_STATE = {
    loading: false,
    loadingTimeline: false,
    doing: false,
    doingError: null,
    byId: {},
    timelineById: {},
    contractsByProfileId: {},
    events: {},
    offset: null,
    total: null,
};

let errorTimeout = null;

const slice = createSlice({
    name: 'contracts',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        LOADING_TIMELINE(state, action) {
            state.loadingTimeline = true;
        },
        DOING(state, action) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
                errorTimeout = null;
            }
            state.doing = true;
        },
        CANCEL(state, action) {
            state.loading = false;
            state.doing = false;
            state.loadingTimeline = false;
        },
        OBTAINED(state, action) {
            state.byId = {
                ...state.byId,
                ...action.payload,
            };
        },
        OBTAINED_PAG(state, action) {
            state.byId = {
                ...state.byId,
                ...action.payload.byId,
            };
            state.offset = action.payload.offset;
            state.total = action.payload.total;
        },
        OBTAINED_TIMELINE(state, action) {
            state.timelineById = {
                ...state.timelineById,
                ...action.payload,
            };
        },
        DOING_ERROR(state, action) {
            state.doingError = action.payload;
        },
        RESET(state, action) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
                errorTimeout = null;
            }
            state.doingError = null;
            state.doing = false;
        },
        RETRIEVED_CONTRACTS_BY_PROFILE_ID(state, action) {
            state.contractsByProfileId = {
                ...state.contractsByProfileId,
                ...action.payload,
            };
        },
        CONTRACT_EVENTS(state, action) {
            state.events = action.payload;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch) => {
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await contractsApiService.getAll();
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((shop) => {
        byId[shop.id] = shop;
    });
    dispatch(slice.actions.OBTAINED(byId));
    dispatch(slice.actions.CANCEL());
};

export const getContractById = (contractId) => async (dispatch) => {
    dispatch(slice.actions.DOING());
    try {
        await contractsApiService.autorenew(contractId);
        const contract = await contractsApiService.get(contractId);
        dispatch(slice.actions.OBTAINED({ [contractId]: contract }));
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.DOING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.RESET()), 5000);
        // console.error(error);
        return null;
    }
    dispatch(slice.actions.CANCEL());
};

export const autorenew = (contractId) => async (dispatch) => {
    dispatch(slice.actions.DOING());
    try {
        await contractsApiService.autorenew(contractId);
        const contract = await contractsApiService.get(contractId);
        dispatch(slice.actions.OBTAINED({ [contractId]: contract }));
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.DOING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.RESET()), 5000);
        // console.error(error);
        return null;
    }
    dispatch(slice.actions.CANCEL());
};

export const terminate = (contractId) => async (dispatch) => {
    dispatch(slice.actions.DOING());
    try {
        await contractsApiService.terminate(contractId);
        const contract = await contractsApiService.get(contractId);
        dispatch(slice.actions.OBTAINED({ [contractId]: contract }));
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.DOING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.RESET()), 5000);
        console.error(error);
        return null;
    }
    dispatch(slice.actions.CANCEL());
};

export const getTimeline = (contractId) => async (dispatch) => {
    dispatch(slice.actions.LOADING_TIMELINE());
    let all = [];
    try {
        all = await contractsApiService.getTimeline(contractId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((timelineItem) => {
        byId[timelineItem.id] = timelineItem;
    });
    dispatch(slice.actions.OBTAINED_TIMELINE({ [contractId]: byId }));
    dispatch(slice.actions.CANCEL());
};

export const getContractsByProfile = (profileId) => async (
    dispatch,
    getState,
) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    let contractsByProfile = [];
    try {
        contractsByProfile = await contractsApiService.getContractsByProfile(
            currentShop,
            profileId,
        );
    } catch (error) {
        console.error(error);
    }
    dispatch(
        slice.actions.RETRIEVED_CONTRACTS_BY_PROFILE_ID({
            [profileId]: contractsByProfile,
        }),
    );
};

export const getContractsByShop = () => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await contractsApiService.getContractsByShop(currentShop);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((contract) => {
        byId[contract.id] = contract;
    });
    dispatch(slice.actions.OBTAINED(byId));
    dispatch(slice.actions.CANCEL());
};

export const getContractsByShopByCreationPag = () => async (
    dispatch,
    getState,
) => {
    const {
        shops: { currentShop },
        contracts: { offset: lastOffset },
    } = getState();
    dispatch(slice.actions.LOADING());
    // let all = [];
    try {
        const {
            contracts: all = [],
            offset,
            total,
        } = await contractsApiService.getContractsByShopByCreationPag(
            currentShop,
            lastOffset,
        );
        const byId = {};
        all.forEach((contract) => {
            byId[contract.id] = contract;
        });
        dispatch(slice.actions.OBTAINED_PAG({ byId, offset, total }));
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const getContractsBySpace = (spaceId) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await contractsApiService.getContractsBySpace(
            currentShop,
            spaceId,
        );
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((shop) => {
        byId[shop.id] = shop;
    });
    // TODO??
    // dispatch(slice.actions.OBTAINED(byId));
    // dispatch(slice.actions.CANCEL());
};

export const addHolder = (contractId, holder) => async (dispatch, getState) => {
    try {
        const contract = await contractsApiService.addHolder(
            contractId,
            holder,
        );
        dispatch(slice.actions.OBTAINED({ [contractId]: contract }));
    } catch (error) {
        console.error('Has error', error);
    }
};

export const doAction = (contractId, action, payload) => async (
    dispatch,
    getState,
) => {
    dispatch(slice.actions.DOING());
    const state = getState();
    const {
        auth: { user },
    } = getState();
    try {
        if (action !== 'remove') {
            const contract = await contractsApiService.doAction(
                contractId,
                action,
                payload,
            );
            const byId = {};
            byId[contract.id] = contract;
            dispatch(slice.actions.OBTAINED(byId));
            dispatch(slice.actions.CANCEL());
            return contract;
        } else {
            if (user.roles.includes('admin')) {
                const contractRemoved = await contractsApiService.remove(
                    contractId,
                );
            }
        }
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.DOING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.RESET()), 5000);
        console.error(error);
        return null;
    }
};

export const toggleFlag = (contractId, flag, value) => async (dispatch) => {
    dispatch(slice.actions.DOING());
    try {
        const contract = await contractsApiService.toggleFlag(
            contractId,
            flag,
            value,
        );
        const byId = {};
        byId[contract.id] = contract;
        dispatch(slice.actions.OBTAINED(byId));
        dispatch(slice.actions.CANCEL());
        return contract;
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.DOING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.RESET()), 5000);
        console.error(error);
        return null;
    }
};

export const getContractEvents = (dateStart, dateEnd) => async (
    dispatch,
    getState,
) => {
    const {
        shops: { currentShop },
    } = getState();
    const events = {
        currentShop,
        dateStart,
        dateEnd,
    };

    try {
        const newContracts = await contractsApiService.getNewContractEvents(
            currentShop,
            dateStart,
            dateEnd,
        );
        events.newContracts = newContracts;

        const renewContracts = await contractsApiService.getRenewContractEvents(
            currentShop,
            dateStart,
            dateEnd,
        );
        events.renewContracts = renewContracts;
        // const renewContracts = await contractsApiService.getRenewContractEvents(
        //     currentShop,
        //     dateStart,
        //     dateEnd,
        // );
        // events.renewContracts = renewContracts;
        const finishedContracts = await contractsApiService.getFinishedContractEvents(
            currentShop,
            dateStart,
            dateEnd,
        );
        events.finishedContracts = finishedContracts;
        dispatch(slice.actions.CONTRACT_EVENTS(events));
    } catch (error) {
        console.log('Error getting contracts events', error);
    }
};
