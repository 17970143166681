import firebase from 'src/lib/firebase';
import { getRef as getShopRef } from './shops';
const db = firebase.firestore();

const serializeOrder = async (id, data) => {
    try {
        const {
            code = null,
            accounting = {},
            amount = null,
            author: {
                ref: authorRef = null,
                type: authorType,
                name: authorName = null,
            },
            currency,
            owner: { ref: ownerRef = null, type: ownerType },
            shop: shopRef = null,
            holder: holderRef = null,
            status,
            times = {},
            // invoiced = null, Probar Y6hjnGUT4bjBcOkTsdC0
            invoiced = null,
        } = data;
        let newInvoiced = null;
        if (invoiced && invoiced.invoices && invoiced.invoices.length > 0) {
            newInvoiced = {
                amount: invoiced.amount,
                status: invoiced.status,
                invoices: [],
            };
            invoiced.invoices.map((invoice) => {
                const { amount, ref: invoiceRef = null } = invoice;
                return newInvoiced.invoices.push({
                    amount,
                    invoiceId:
                        invoiceRef && invoiceRef.id
                            ? invoiceRef.id
                            : invoiceRef,
                });
            });
        }
        const newTimes = {};
        Object.keys(times).forEach(
            (keyTime) =>
                (newTimes[keyTime] = new Date(times[keyTime].toDate())),
        );

        const author = { type: authorType };
        if (authorRef) author.authorId = authorRef.id;
        if (authorName) author.name = authorName;

        const owner = { type: ownerType };
        if (ownerRef) owner.ownerId = ownerRef.id;

        let isPaid = false;
        if (
            accounting.types &&
            accounting.types.credit &&
            accounting.types.credit.status &&
            accounting.types.credit.status === 'completed'
        )
            isPaid = true;

        let isInvoiced = false;
        if (
            newInvoiced &&
            newInvoiced.status &&
            newInvoiced.status === 'completed'
        )
            isInvoiced = true;

        let holder = null;
        if (holderRef && typeof holderRef !== 'string') {
            const holderDoc = await holderRef.get();
            let { name, docNumber, status } = holderDoc.data();
            if (typeof name !== 'string') {
                name = `${name.first || ''}${name.first && name.last ? ' ' : ''
                    }${name.last}`;
            }
            holder = {
                name,
                docNumber: docNumber.value,
                status,
                path: holderRef.path,
            };
        }
        return {
            id,
            code,
            accounting,
            amount,
            currency,
            author,
            owner,
            shopId: shopRef && shopRef.id ? shopRef.id : shopRef,
            status: status || 'n/a',
            times: newTimes,
            invoiced: newInvoiced,
            isPaid,
            isInvoiced,
            holder,
            date: newTimes.createdAt,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const serializeOrderItem = (id, data) => {
    const { itemNumber, type, times = {}, price = null, params = null } = data;
    let newParams = null;
    if (params) {
        if (type === 'contractSpace' || type === 'renewContractSpace') {
            newParams = {
                amount: params.amount,
                plan: params.plan,
                startAt: params.startAt ? params.startAt.toDate() : null,
            };
            if (type === 'contractSpace') newParams.spaceId = params.space.id;
            if (type === 'renewContractSpace')
                newParams.contractId = params.contract.id;
        }
    }
    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );
    return {
        id,
        itemNumber,
        type,
        price,
        times: newTimes,
        params: newParams,
    };
};

const processOrderItem = async (itemDoc) => {
    if (!itemDoc.exists) return null;
    const orderItem = await serializeOrderItem(itemDoc.ref.id, itemDoc.data());
    return orderItem;
};

export const process = async (document) => {
    if (!document.exists) return null;
    const order = await serializeOrder(document.ref.id, document.data());
    return order;
};
export const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getCollectionRef = () => db.collection('orders');
export const getRef = (orderId) => getCollectionRef().doc(orderId);

export const getAll = async (shopId) => {
    if (!shopId) return [];
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};

export const ordersByCreationPag = async (shopId, offset = null) => {
    if (!shopId) return [];
    const shopRef = getShopRef(shopId);
    let query = getCollectionRef()
        .where('shop', '==', shopRef)
        .where('status', 'in', ['accepted', 'completed', 'executing', 'draft'])
        .orderBy('times.createdAt', 'desc');
    const { docs: totalOrders } = await query.get();
    if (offset) query = query.startAfter(offset);
    const { docs } = await query.limit(50).get();
    let newOffset = null;
    if (docs.length) {
        const lastDate = docs[docs.length - 1].data().times.createdAt.toDate();
        newOffset = lastDate;
    }
    const allPromises = docs.map((doc) => process(doc));
    const orders = await Promise.all(allPromises);

    return { orders, offset: newOffset, total: totalOrders.length };
};

export const get = async (orderId) => process(await getRef(orderId).get());

export const subscribe = (orderId, callback) =>
    getRef(orderId).onSnapshot(async (doc) => {
        const data = await process(doc);
        callback(data);
    });

export const getOrderItems = async (orderId) => {
    const { docs } = await getRef(orderId)
        .collection('items')
        .get();
    return processAll(docs, processOrderItem);
};

export const register = async (orderDraft, uid) => {
    const createFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsOrderCreate');
    const { data } = await createFunc({ ...orderDraft, uid });
    return get(data.orderId);
};

export const doAction = async (orderId, action) => {
    const doActionFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsOrderDoAction');
    const { data } = await doActionFunc({ orderId, action });
    return get(data.orderId);
};

export const pay = async (orderId, payload) => {
    const payFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsOrderPayOrder');
    const { data } = await payFunc({ orderId, ...payload });
    return get(data.orderId);
};

export const changeHolder = async (orderId, holderPath) => {
    const changeHolderFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsOrderManageHolder');
    // const changeHolderFunc = firebase.functions().httpsCallable('srcOnCallFunctionsOrderDoAction');
    const { data } = await changeHolderFunc({ orderId, holderPath });
    return get(data.orderId);
};

export const getOrdersByProfile = async (shopId, profileId) => {
    if (!shopId) return [];
    const userRef = db.collection('profiles').doc(profileId);
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .where('owner.ref', '==', userRef)
        .orderBy('times.createdAt', 'desc')
        .get();
    return processAll(docs, process);
};
