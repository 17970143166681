import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as authActionsSlice from 'src/slices/auth';

import {
    Avatar,
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
    withStyles,
} from '@material-ui/core';
// import useAuth from 'src/hooks/useAuth';
// import { capitalize, strings } from 'src/utils/strings';
// import { changeLanguage } from 'src/translations/i18n';
// import { render } from 'nprogress';
import wait from 'src/utils/wait';

const styles = (theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    popover: {
        width: 200,
    },
});

const ref = React.createRef();

class Account extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const { user } = nextProps;
        return { user };
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }

    // const ref = useRef(null);
    // const { user, logout } = useAuth();
    // const { enqueueSnackbar } = useSnackbar();
    // const [isOpen, setOpen] = useState(false);
    // const [languageCode, setLanguageCode] = useState('en');

    handleOpen() {
        this.setState({ isOpen: true });
    }

    handleClose() {
        this.setState({ isOpen: false });
    }

    async handleLogout() {
        const { enqueueSnackbar } = useSnackbar();
        const { history } = this.props;
        try {
            this.handleClose();
            await this.logout();
            history.push('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                variant: 'error',
            });
        }
    }

    async handleChangeLanguage() {
        const { history, authActions } = this.props;
        // return;
        authActions.changeAppLanguage('en-EN');
        this.handleClose();
        await wait(1500);
        history.go(0);
    }

    render() {
        const { classes } = this.props;
        const { isOpen, user } = this.state;
        // if (!user) return null;
        return (
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    component={ButtonBase}
                    onClick={this.handleOpen}
                    ref={ref}>
                    <Avatar
                        alt="User"
                        className={classes.avatar}
                        src={user && user.avatar ? user.avatar : null}
                    />
                    <Hidden smDown>
                        <Typography variant="h6" color="inherit">
                            {user && user.name ? user.name : ''}
                        </Typography>
                    </Hidden>
                </Box>
                <Menu
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    PaperProps={{ className: classes.popover }}
                    getContentAnchorEl={null}
                    anchorEl={ref.current}
                    open={isOpen}>
                    <MenuItem component={RouterLink} to="/app/social/profile">
                        Profile
                    </MenuItem>
                    <MenuItem component={RouterLink} to="/app/account">
                        Account
                    </MenuItem>
                    <MenuItem onClick={this.handleChangeLanguage}>Change Language</MenuItem>
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user } }) => {
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActionsSlice, dispatch),
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles, { withTheme: true })(Account)),
);
