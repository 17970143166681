import React, { useRef, useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles,
    Button,
} from '@material-ui/core';
import { ShopTwo as ShopsIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';
import { getAll as getAllShops, selectShop } from 'src/slices/shops';
import OnlineIndicator from 'src/components/template/elements/OnlineIndicator';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2),
    },
    listItemText: {
        marginRight: theme.spacing(1),
    },
    icon: {
       backgroundColor: theme.palette.secondary.main,
       color: theme.palette.secondary.contrastText
    },
}));

const Shops = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const { byId: shopsById } = useSelector((state) => state.shops);
    let { currentShop } = useSelector((state) => state.shops);

    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setSelectedShop = (id) => {
        dispatch(selectShop(id));
    };

    useEffect(() => {
        dispatch(getAllShops());
    }, [dispatch]);

    // if (!currentShop && Object.values(shopsById).length > 0) {
    //     currentShop = Object.values(shopsById)[0].id;
    // }

    // const currentShopName = currentShop && shopsById[currentShop] ? shopsById[currentShop].name : '';

    return (
        <>
            {/* <Typography
                onClick={handleOpen} 
                variant="h4"
                color="inherit"
                style={{ marginRight: 5 }}>
                {currentShopName}
            </Typography> */}
            <Tooltip title="Shops selector">
                <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                    <SvgIcon fontSize="small">
                        <ShopsIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}>
                <Typography variant="h4" color="textPrimary">
                    Shops
                </Typography>
                <Box mt={2}>
                    <List disablePadding>
                        {Object.values(shopsById).map((shop) => {
                            return (
                                <ListItem disableGutters key={shop.id}>
                                    {/* <ListItemAvatar>
                                        <Avatar
                                            alt="Person"
                                            src={contact.avatar}
                                        />
                                    </ListItemAvatar> */}
                                    <ListItemText
                                        className={classes.listItemText}
                                        disableTypography
                                        primary={
                                            <Button
                                                onClick={() =>{
                                                    setSelectedShop(shop.id);
                                                    handleClose();
                                                }}>
                                                {shop.name}
                                            </Button>
                                        }
                                    />
                                    {shop.id === currentShop ? (
                                        <OnlineIndicator
                                            size="small"
                                            status="online"
                                        />
                                    ) : null}
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Popover>
        </>
    );
};

export default Shops;
