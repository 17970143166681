import firebase from 'src/lib/firebase';
import { process as processProfile, processHolder } from './profiles';

const db = firebase.firestore();

const process = async (document) => {
    if (!document.exists) return null;
    const {
        document: docToValidateRef,
        type,
        times = {},
        owner: ownerRef,
    } = document.data();
    const docToValidateDoc = await docToValidateRef.get();
    const documentType = docToValidateRef.parent.id;
    const ownerDoc = await ownerRef.get();
    const newTimes = {};
    Object.keys(times).forEach((key) => (newTimes[key] = times[key].toDate()));
    return {
        validationId: document.ref.id,
        type,
        document:
            documentType === 'profiles'
                ? await processProfile(docToValidateDoc, true)
                : await processHolder(docToValidateDoc),
        documentType,
        owner: await processProfile(ownerDoc),
        times: newTimes,
    };
};

export const get = async (validationId) => {
    const document = await db
        .collection('validations')
        .doc(validationId)
        .get();
    if (!document.exists) return null;
    return process(document);
};

export const validate = async (validationId, validations) => {
    if (validations.birthDate) {
        validations.birthDate.value = validations.birthDate.value.toISOString();
    }
    // return;
    const validationFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsValidationsValidateDocNumber');
    return validationFunc({ validationId, validations });
};

const getCollectionRef = () => db.collection('validations');

export const add = async (type, document, fields, author) => getCollectionRef().add({ type, document: document.ref ? document.ref : document, fields, author });

export default {
    get,
    validate,
    add,
};
