import firebase from 'src/lib/firebase';
import { getRef as getOrderRef } from './orders';
const db = firebase.firestore();

const serializePayment = (id, data) => {
    const {
        amount = null,
        currency,
        description,
        owner: { ref: ownerRef = null, type: ownerType },
        status,
        times,
        source: sourceRef = null,
        // invoiced = null, Probar Y6hjnGUT4bjBcOkTsdC0
    } = data;

    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = new Date(times[keyTime].toDate())),
    );
    const owner = { type: ownerType };
    if (ownerRef) owner.ownerId = ownerRef.id;
    let orderId = null;
    if (sourceRef) orderId = sourceRef.id; 
    return {
        id,
        amount,
        currency,
        description,
        owner,
        status: status || 'n/a',
        times: newTimes,
        orderId,
        // createdAt: times.createdAt.toDate(),
        // updatedAt: times.updatedAt.toDate(),
        date: newTimes.updatedAt ? newTimes.updatedAt : (newTimes.createdAt ? newTimes.createdAt : null),
    };
};

const serializePaymentIntent = (id, data) => {
    const {
        amount = null,
        // description,
        author: { ref: authorRef = null, type: authorType },
        status,
        times = {},
        type,
        params,
        stripe: stripeData = null,
    } = data;
    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );
    const author = { type: authorType };
    if (authorRef) author.authorId = authorRef.id;
    const stripe = {};
    if (stripeData) {
        const { description = null, next_action: nextAction = null } = stripeData;
        stripe.description = description;
        stripe.nextAction = nextAction;
    }
    return {
        id,
        amount,
        // description,
        author,
        status: status || 'n/a',
        type,
        times: newTimes,
        params,
        stripe: { ...stripeData, nextAction: stripe.nextAction  || null },
        date: newTimes.updatedAt ? newTimes.updatedAt : (newTimes.createdAt ? newTimes.createdAt : null),
    };
};

export const process = async (document) => {
    if (!document.exists) return null;
    const payment = serializePayment(document.ref.id, document.data());
    return payment;
};

export const processPaymentIntent = async (document) => {
    if (!document.exists) return null;
    const paymentIntent = serializePaymentIntent(document.ref.id, document.data());
    return paymentIntent;
};

const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getCollectionRef = () => db.collection('payments');
export const getRef = (paymentId) => getCollectionRef().doc(paymentId);
export const getIntentCollectionRef = (paymentId) => getRef(paymentId).collection('paymentIntents');
export const getIntentRef = (paymentId, intentId) => getIntentCollectionRef(paymentId).doc(intentId)

export const getAll = async () => {
    const { docs } = await getCollectionRef().orderBy('times.createdAt', 'desc').get();
    return processAll(docs, process);
};

export const get = async (paymentId) => process(await getRef(paymentId).get());

export const getOrderPayments = async (orderId) => {
    const orderRef = getOrderRef(orderId);
    const { docs } = await getCollectionRef()
        .where('source', '==', orderRef)
        .get();
    return processAll(docs, process);
};

export const getIntent = async (paymentId, intentId) => processPaymentIntent(await getIntentRef(paymentId, intentId).get());

export const getLastPaymentIntent = async (paymentId) => {
    const paymentRef = getRef(paymentId);
    const { docs } = await paymentRef
        .collection('paymentIntents')
        .orderBy('times.createdAt', 'desc')
        .limit(1)
        .get();
    return processAll(docs, processPaymentIntent);
};
export const getIntentsByPaymentId = async (paymentId) => {
    const paymentRef = getRef(paymentId);
    const { docs } = await paymentRef
        .collection('paymentIntents')
        .orderBy('times.createdAt', 'desc')
        .get();
    return processAll(docs, processPaymentIntent);
};

export const createIntent = async (paymentId, paymentMethod, amount) => {
    const createIntentFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsPaymentsCreateIntent');
    // const changeHolderFunc = firebase.functions().httpsCallable('srcOnCallFunctionsOrderDoAction');
    const { data } = await createIntentFunc({ paymentId, paymentMethod, amount });
    return getIntent(paymentId, data.intentId);
};

export const cancel = async (paymentId) => {
    const createIntentFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsPaymentsCancel');
    // const changeHolderFunc = firebase.functions().httpsCallable('srcOnCallFunctionsOrderDoAction');
    await createIntentFunc({ paymentId });
    return get(paymentId);
};
