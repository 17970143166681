export const TRANSLATIONS_EN = {
    happening: `Here's what's happening`,
    signIn: 'Sign in',
    trasteroxPlatform: 'trasterox Platform',
    auth: {
        sendSmsCode: 'send SMS code',
        validateCode: 'validate code',
        smsCode: 'SMS code',
        invalidPhone: 'Phone number is not valid',
        requiredPhone: 'Phone number is required',
        maxCodeLength: 'Code must have 6 digits',
        minCodeLength: 'Code must have 6 digits',
        requiredSMS: 'SMS code is required',
        requiredCountryCode: 'country Code is required',
        smsSent: 'We have sent you an SMS code, please enter it to continue.',
    },
    phone: 'phone',
    countryCode: 'country code',
    reports: 'reports',
    management: 'management',
    dashboard: 'dashboard',
    schedule: 'schedule',
    orders: 'orders',
    order: {
        order: 'order',
        orderInfo: 'order Information',
        accept: 'accept',
        acceptOrder: 'accept order',
        acceptOrderQuestion: 'are you sure to accept order',
        cancelOrder: 'cancel order',
        cancelOrderQuestion: 'are you sure to cancel order',
        execute: 'execute',
        executeOrder: 'execute order',
        executeOrderQuestion: 'are you sure to execute order',
        doInvoice: 'do invoice',
        doInvoiceQuestion: 'Do you want to invoice order',
        markAsCompleted: 'mark as completed',
        markAsCompletedQuestion: 'Do you want to mark as completed order',
        noItems: 'not items available',
        vat: 'VAT',
        items: 'items',
    },
    contracts: 'contracts',
    contractObject: 'contract',
    contract: {
        contract: 'contract',
        contractInfo: 'contract Information',
        holder: 'holder',
        holders: 'holders',
        now: 'contract now',
        space: 'contract space',
        unlockContract: 'unlock Contract',
        unlockContractQuestion: 'Are you sure to unblock contract',
        lockContract: 'lock Contract',
        lockContractQuestion: 'Are you sure to block contract',
        renewContract: 'renew Contract',
        renewContractQuestion: '¿Are you sure to renew contract {{code}} for {{total}} € with new end to ',
        finishContract: 'finish Contract',
        finishContractQuestion: 'Are you sure to finish contract',
        removeContract: 'remove Contract',
        removeContractQuestion: 'Are you sure to remove contract',
        autorenew: 'autorenew',
        renewAtEnd: 'renew at End',
    },
    invoices: 'invoices',
    invoice: {
        invoice: 'invoice',
        invoiceInfo: 'invoice information',
        syncWithZoho: 'sync with Zoho',
        syncWithZohoQuestion: 'Do you want to sync with Zoho?',
        invoiceNumber: 'invoice Number',
    },
    customers: 'customers',
    customer: 'customer',
    messages: 'messages',
    payments: 'payments',
    payment: {
        payReintent: `¿Are you sure to reintent payment of {{amount}}€ with concept "{{concept}}"?`,
        createPayment: 'create Payment',
        cancelLastPayment: 'cancel last payment intent',
        stripePaymentMethod: 'Stripe payment method',
        paymentMethod: 'payment method',
        intents: 'intents',
        defaultConcept: 'order payment to Trasterox',
        paymentInfo: 'payment information',
        paymentDetails: 'payment details',
        lastPaymentIntent: 'last payment intent',
        nextAction: 'next action',
    },
    pay: 'pay',
    profiles: 'profiles',
    profilesNotFound: 'profiles not found',
    profileSelect: 'select a customer',
    spaces: 'spaces',
    space: {
        space: 'space',
        dimensions: 'dimensions',
        area: 'area',
        volume: 'volume',
        prices: 'prices',
        category: 'category',
    },
    status: 'status',
    type: 'type',
    spaceInfo: 'space Information',
    details: 'details',
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
    periodSelect: 'select a period',
    code: 'code',
    startAt: 'start at',
    updatedAt: 'updated at',
    endAt: 'end At',
    createdAT: 'create At',
    openDoor: 'open Door',
    maintenance: 'maintenance',
    changeToMaintenance: 'change to maintenance',
    changeToMaintenanceQuestion:
        'are you sure to change status to maintenance?',
    free: 'free',
    changeToFree: 'change to free',
    changeToFreeQuestion: 'Are you sure to change status to free?',
    rented: 'rented',
    changeToRented: 'change to rented',
    changeToRentedQuestion: 'Are you sure to change status to rented?',
    totalAmount: 'total amount',
    close: 'close',
    profile: {
        profileInfo: 'profile information',
        goToProfile: 'go to profile',
        phoneCall: 'phone call',
        sendWhatsapp: 'send a Whatsapp',
        name: 'name',
        lastValidation: 'last validation',
        paymentMethods: 'payment methods',
        addPaymentMethod: 'add payment method',
        addInvitation: 'add Invitation',
        addCompany: 'add Holder',
        cardBrand: 'Brand',
        cardNumber: 'card number',
        expDate: 'expiration date',
        accessData: 'access data',
        email: 'email',
        privacyPolicy: 'privacy Policy',
        language: 'language',
        roles: 'roles',
        personalData: 'personal data',
        surname: 'surename',
        docNumber: 'doc ID',
        birthdate: 'birthdate',
        address: 'address',
        street: 'street',
        city: 'city',
        zipCode: 'zip Code',
        state: 'state',
        country: 'country',
        noValidation: 'no validation yet',
        company: 'company',
        companyDocNumber: 'company Doc Number',
        invitationCreated: 'Invitatiod created',
    },
    description: 'description',
    unitPrice: 'unit prize',
    quantity: 'Qty',
    subTotal: 'subtotal',
    totalLine: 'line total',
    total: 'total',
    tax: 'TAX',
    cancel: 'cancel',
    date: 'date',
    action: 'action',
    amount: 'amount',
    concept: 'concept',
    lock: 'lock',
    unblock: 'unblock',
    finish: 'finish',
    renew: 'renew',
    authorizations: 'authorizations',
    timeline: 'timeline',
    timelineChrono: 'timeline Chrono',
    goToMessages: 'go to messages',
    warnings: 'warnings',
    active: 'active',
    add: 'add',
    threads: 'threads',
    messageThreads: 'message Threads',
    available: 'available',
    access: 'access',
    service: 'service',
    finished: 'finished',
    enabled: 'enabled',
    blocked: 'blocked',
    notVat: 'not vat',
    completed: 'completed',
    accepted: 'accepted',
    draft: 'draft',
    cancelled: 'cancelled',
    failed: 'failed',
    copyLink: 'copy link',
    webPayment: 'web payment',
    showErrorMessage: 'show error message',
    paid: 'paid',
    registered: 'registered',
    warning: 'aviso',
    start: 'start',
    registering: 'registering',
    checking: 'checking',
    applicated: 'applicated',
    events: 'events',
    unlockRequest: 'unlock request',
    admin: 'administrator',
    authorization: {
        new: 'new authorization',
    },
    occupationPlan: 'occupation Plan',
    loadingMore: 'loading more',
    addCard: 'add card',
    remove: 'remove',
};
