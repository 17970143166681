import firebase from 'src/lib/firebase';
const db = firebase.firestore();

const serializeAuthorization = (id, data) => {
    const {
        active = true,
        name,
        phone,
        code,
        startAt = null,
        endAt = null,
        contract: { id: contractId },
        space,
    } = data;
    return {
        id,
        active,
        name,
        phone,
        code,
        startAt: startAt ? startAt.toDate() : null,
        endAt: endAt ? endAt.toDate() : null,
        contractId,
        shopId: space.parent.parent.id,
        spaceId: space.id,
    };
};

const process = (document) => {
    if (!document.exists) return null;
    const authorization = serializeAuthorization(
        document.ref.id,
        document.data(),
    );
    return authorization;
};

const getAllRef = (uid) =>
    db
        .collection('authorizations')
        .where('owner', '==', db.collection('profiles').doc(uid));
const getAllMyRef = (uid) =>
    db
        .collection('authorizations')
        .where('profile', '==', db.collection('profiles').doc(uid));

export const subscribeMy = (uid, callback) =>
    getAllMyRef(uid).onSnapshot(async (snapshot) => {
        if (!snapshot) return;
        const { docs } = snapshot;
        const allPromises = docs.map((doc) => process(doc));
        callback(await Promise.all(allPromises));
    });

export const getAll = async (uid) => {
    const { docs } = await getAllRef(uid).get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};

export const getAllMy = async (uid) => {
    const { docs } = await db
        .collection('authorizations')
        .where('profile', '==', db.collection('profiles').doc(uid))
        .get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};

export const get = async (authorizationId) => {
    const authorizationDoc = await db
        .collection('authorizations')
        .doc(authorizationId)
        .get();
    return process(authorizationDoc);
};

const executeRemoteFunction = async (functionName, params) => {
    const remoteFunction = firebase.functions().httpsCallable(functionName);
    const {
        data: { authorizationId },
    } = await remoteFunction(params);
    return get(authorizationId);
};

export const register = async (uid, authorizationData) =>
    executeRemoteFunction('srcOnCallFunctionsAuthorizationsRegister', {
        ...authorizationData,
        uid,
    });

export const toogle = async (uid, authorizationData) =>
    executeRemoteFunction('srcOnCallFunctionsAuthorizationsToogle', {
        ...authorizationData,
        uid,
    });

export const add = async (uid, code) =>
    executeRemoteFunction('srcOnCallFunctionsAuthorizationsAdd', { uid, code });

export const getAuthorizationsByContract = async (contractId) => {
    const { docs } = await db
        .collection('authorizations')
        .where('contract', '==', db.collection('contracts').doc(contractId))
        .get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};
