import firebase from 'src/lib/firebase';

const db = firebase.firestore();

export const getShopRef = () => db.collection('shops');
export const getKpiRef = () => db.collection('kpis');

export const getDashboardDataDiary = ({ shopId, timeRange = {}, diary }) => {
    const { dateStart = null, dateEnd = null } = timeRange;
    return getShopRef()
        .doc(shopId)
        .collection('data')
        .doc('diaries')
        .collection(diary)
        .where('date.date', '>=', dateStart)
        .where('date.date', '<=', dateEnd)
        .orderBy('date.date', 'asc');
};

export const getKpiDoc = (kpi) => {
    return getKpiRef().doc(kpi);
};

export const getKpi = async (kpiId) => {
    const kpiDoc = await getKpiDoc(kpiId).get();
    const kpiData = { [kpiDoc.ref.id]: kpiDoc.data() };
    return kpiData;
};

export const getShopDiary = async ({ shopId, timeRange = {}, diary }) => {
    const { dateEnd } = timeRange;
    const { docs } = await getDashboardDataDiary({
        shopId,
        timeRange,
        diary,
    }).get();
    return { results: docs, date: dateEnd };
};

export const processKpi = async (kpi) => {
    const {
        diary = [],
        kpiData: { groupByInfo = {} },
    } = kpi;
    let summary = {};
    if (diary) {
        summary = diary.reduce(
            (summary, item) => processGroup(summary, item.data(), groupByInfo),
            {},
        );
    }
    return summary;
};

const processGroup = (summary, diaryData, groupBy) => {
    if (!summary.all) summary.all = [];
    summary.all.push(diaryData);

    Object.keys(groupBy).forEach((accumulatorGroup) => {
        if (!summary[accumulatorGroup]) summary[accumulatorGroup] = {};
        if (accumulatorGroup !== 'groupBy') {
            groupBy[accumulatorGroup].forEach((accumulatorGroupField) => {
                if (!summary[accumulatorGroup][accumulatorGroupField])
                    summary[accumulatorGroup][accumulatorGroupField] = 0;
            });
        }
    });

    Object.keys(groupBy).forEach((accumulatorGroup) => {
        if (accumulatorGroup === 'groupBy') {
            summary.groupBy = doGroupBy(
                summary.groupBy,
                diaryData,
                groupBy[accumulatorGroup],
                groupBy,
            );
        } else {
            groupBy[accumulatorGroup].forEach((accumulatorGroupField) => {
                const value = Object.byString(diaryData, accumulatorGroupField);
                const { volume, area } = diaryData.space;
                switch (accumulatorGroup) {
                    case 'volumeBy':
                        summary[accumulatorGroup][accumulatorGroupField] +=
                            value * volume;
                        break;
                    case 'areaBy':
                        summary[accumulatorGroup][accumulatorGroupField] +=
                            value * area;
                        break;
                    default:
                        summary[accumulatorGroup][
                            accumulatorGroupField
                        ] += value;
                        break;
                }
            });
        }
    });
    return summary;
};

const doGroupBy = (summary, diaryData, groupBy, parentGroupBy = {}) => {
    Object.keys(groupBy).forEach((group) => {
        const groupName = Object.byString(diaryData, group);
        if (!summary[group]) summary[group] = {};
        if (!summary[group][groupName]) summary[group][groupName] = {};

        let newGroupBy = groupBy[group];
        if (newGroupBy === 'inherit') {
            newGroupBy = { ...parentGroupBy };
            delete newGroupBy.groupBy;
        }
        summary[group][groupName] = processGroup(
            summary[group][groupName],
            diaryData,
            newGroupBy,
        );
    });
    return summary;
};

// let currentOccupation = {};

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

export const getVolumeOccupationSerie = ({
    serieName,
    level,
    summarized,
    totals,
    dateInfo,
    color,
    title,
    isCurrent = false,
}) => {
    const serie = { area: {} };
    let accumulatedRentedVolume = 0;
    let accumulatedRentedArea = 0;
    level.serie.forEach((item) => {
        if (level.serie[level.serie.length - 1] === item) {
            // Es el último nivel
            if (!serie[serieName]) {
                serie[serieName] = {
                    // title: level[serieName].label,
                    // units: item.split('.')[1],
                    units: level.units,
                    color,
                    data: [],
                    labels: [],
                    title,
                    isCurrent,
                };
            }
            // Area
            if (!serie.area[serieName]) {
                serie.area[serieName] = {
                    title: level[serieName].label,
                    // units: item.split('.')[1],
                    units: level.units,
                    data: [],
                    labels: [],
                    isCurrent,
                };
            }
            if (summarized.groupBy[item]) {
                let initialVolumeOccupationPercentage = null;
                let initialAreaOccupationPercentage = null;
                const { totalVolume, totalArea } = totals;

                if (!initialVolumeOccupationPercentage) {
                    initialVolumeOccupationPercentage =
                        Math.round(
                            ((accumulatedRentedVolume * 100) / totalVolume) *
                                100,
                        ) / 100;
                    initialAreaOccupationPercentage =
                        Math.round(
                            ((accumulatedRentedArea * 100) / totalArea) * 100,
                        ) / 100;
                }
                let baseKey = null;
                switch (level.units) {
                    case 'day':
                    case 'month':
                        baseKey = 1;
                        break;
                    case 'quarter':
                        baseKey = 1;
                        if (dateInfo.quarter)
                            baseKey = dateInfo.quarter * 3 - 2;
                        break;
                    case 'semester':
                        baseKey = 6;
                        if (dateInfo.semester > 1) baseKey = 7;
                        // baseKey = 6;
                        break;
                    case 'weekday':
                        baseKey = 0;
                        break;
                    default:
                        break;
                }
                let initialKey = false;
                Object.keys(summarized.groupBy[item]).forEach((key) => {
                    if (!initialKey) {
                        initialKey = true;
                        if (parseInt(key) !== baseKey) {
                            serie[serieName].labels.push(baseKey);
                            serie[serieName].data.push(
                                initialVolumeOccupationPercentage,
                            );
                            serie.area[serieName].labels.push(baseKey);
                            serie.area[serieName].data.push(
                                initialAreaOccupationPercentage,
                            );
                        }
                    }
                    // TODO: Calcular area
                    // const { totalVolume, totalArea } = totals;
                    // Volume
                    const {
                        newContractSpace: newContractSpaceVolume = 0,
                        newFinishedContractSpace: newFinishedContractSpaceVolume = 0,
                    } = summarized.groupBy[item][key].volumeBy;
                    accumulatedRentedVolume +=
                        newContractSpaceVolume - newFinishedContractSpaceVolume;
                    const volumeRentedPercetage =
                        Math.round(
                            ((accumulatedRentedVolume * 100) / totalVolume) *
                                100,
                        ) / 100;
                    serie[serieName].labels.push(key);
                    serie[serieName].data.push(volumeRentedPercetage);
                    // Area
                    const {
                        newContractSpace: newContractSpaceArea = 0,
                        newFinishedContractSpace: newFinishedContractSpaceArea = 0,
                    } = summarized.groupBy[item][key].areaBy;
                    accumulatedRentedArea +=
                        newContractSpaceArea - newFinishedContractSpaceArea;
                    const areaRentedPercetage =
                        Math.round(
                            ((accumulatedRentedArea * 100) / totalArea) * 100,
                        ) / 100;
                    serie.area[serieName].labels.push(key);
                    serie.area[serieName].data.push(areaRentedPercetage);
                });
            }
            return;
        } else {
            Object.keys(summarized.groupBy[item]).forEach((key) => {
                const lastToProcess =
                    parseInt(key) >= dateInfo[item.split('.')[1]];
                if (lastToProcess) {
                    if (
                        summarized.groupBy[item] &&
                        summarized.groupBy[item][key]
                    ) {
                        summarized = {
                            ...summarized.groupBy[item][key],
                        };
                    }
                } else {
                    if (parseInt(key) < dateInfo[item.split('.')[1]]) {
                        const {
                            newContractSpace: newContractSpaceVolume = 0,
                            newFinishedContractSpace: newFinishedContractSpaceVolume = 0,
                        } = summarized.groupBy[item][key].volumeBy;
                        accumulatedRentedVolume +=
                            newContractSpaceVolume -
                            newFinishedContractSpaceVolume;
                        const {
                            newContractSpace: newContractSpaceArea = 0,
                            newFinishedContractSpace: newFinishedContractSpaceArea = 0,
                        } = summarized.groupBy[item][key].areaBy;
                        accumulatedRentedArea +=
                            newContractSpaceArea - newFinishedContractSpaceArea;
                    }
                }
            });
        }
    });
    return serie[serieName];
};

export const levelPeriods = {
    1: {
        serie: ['date.year', 'date.month'],
        pastDate: { years: -1 },
        pastSerie: { years: -1 },
        // units: 'date.month',
        units: 'month',
        current: { id: 'thisYear', label: 'This year' },
        previous: { id: 'lastYear', label: 'Last year' },
    },
    2: {
        serie: ['date.year', 'date.semester', 'date.month'],
        pastDate: { months: -6 },
        pastSerie: { years: -1 },
        units: 'semester',
        current: { id: 'thisSemeter', label: 'This semester' },
        previous: { id: 'lastSemester', label: 'Last semester' },
    },
    3: {
        serie: ['date.year', 'date.quarter', 'date.month'],
        pastDate: { years: -1 },
        pastSerie: { years: -1 },
        units: 'quarter',
        current: { id: 'thisQuarter', label: 'This quarter' },
        previous: { id: 'lastQuarter', label: 'Last quarter' },
    },
    4: {
        serie: ['date.year', 'date.month', 'date.day'],
        pastDate: { months: -1 },
        pastSerie: { months: -1 },
        units: 'day',
        current: { id: 'thisMonth', label: 'This month' },
        previous: { id: 'lastMonth', label: 'Last month' },
    },
    5: {
        serie: ['date.year', 'date.month', 'date.week', 'date.weekDay'],
        pastDate: { days: -7 },
        pastSerie: { days: -7 },
        units: 'weekday',
        current: { id: 'thisWeek', label: 'This week' },
        previous: { id: 'lastWeek', label: 'Last week' },
        labels: { current: 'This week', previous: 'Previous week' },
    },
};

export const getIncomesSerie = ({
    serieName,
    level,
    summarized,
    totals,
    dateInfo,
    color,
    title,
    isCurrent = false,
    accumulatedOption = false,
}) => {
    const serie = { area: {} };
    let accumulatedIncomesTotal = 0;
    // let accumulatedIncomesSubTotal = 0;
    level.serie.forEach((item) => {
        if (level.serie[level.serie.length - 1] === item) {
            // Es el último nivel
            if (!serie[serieName]) {
                serie[serieName] = {
                    units: level.units,
                    color,
                    data: [],
                    labels: [],
                    title,
                    isCurrent,
                };
            }
            if (summarized.groupBy[item]) {
                let baseKey = null;
                switch (level.units) {
                    case 'day':
                    case 'month':
                        baseKey = 1;
                        break;
                    case 'quarter':
                        baseKey = 1;
                        if (dateInfo.quarter)
                            baseKey = dateInfo.quarter * 3 - 2;
                        break;
                    case 'semester':
                        baseKey = 6;
                        if (dateInfo.semester > 1) baseKey = 7;
                        // baseKey = 6;
                        break;
                    case 'weekday':
                        baseKey = 0;
                        break;
                    default:
                        break;
                }
                let initialKey = false;
                Object.keys(summarized.groupBy[item]).forEach((key) => {
                    if (!initialKey) {
                        initialKey = true;
                        if (parseInt(key) !== baseKey) {
                            serie[serieName].labels.push(baseKey);                        }
                    }
                    serie[serieName].labels.push(key);
                    const {
                        incomeTotal: newIncomeTotal = 0,
                        // incomeSubTotal: newIncomeSubTotal = 0,
                    } = summarized.groupBy[item][key].totalBy;
                    accumulatedIncomesTotal += newIncomeTotal;
                    // accumulatedIncomesSubTotal += newIncomeSubTotal;

                    let roundTotal = Math.round(newIncomeTotal * 100) / 100;
                    if (accumulatedOption) {
                        roundTotal =
                            Math.round(accumulatedIncomesTotal * 100) / 100;
                    }
                    serie[serieName].data.push(roundTotal);
                });
            }
            return;
        } else {
            Object.keys(summarized.groupBy[item]).forEach((key) => {
                const lastToProcess =
                    parseInt(key) >= dateInfo[item.split('.')[1]];
                if (lastToProcess) {
                    if (
                        summarized.groupBy[item] &&
                        summarized.groupBy[item][key]
                    ) {
                        summarized = {
                            ...summarized.groupBy[item][key],
                        };
                    }
                }
            });
        }
    });
    return serie[serieName];
};
