import { createSlice } from '@reduxjs/toolkit';
import authApiService from 'src/services/auth';
import { getWithDispatch } from 'src/slices/profiles';
import { changeLanguage } from 'src/translations/i18n';
import * as profilesApiService from 'src/services/profiles';
// import { useReducer } from 'react';

// Redux Actions
export const USER_LOGGING = 'trasterox.auth.USER_LOGGING';
export const USER_LOGGED = 'trasterox.auth.USER_LOGGED';
export const USER_LOGOUT = 'trasterox.auth.USER_LOGOUT';
export const USER_LOGGING_CANCEL = 'trasterox.auth.USER_LOGGING_CANCEL';

const INITIAL_STATE = {
    user: null,
    userLogged: null,
    authenticating: false,
    isAuthenticated: false,
};

const slice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        USER_LOGGING(state, action) {
            return { ...state, authenticating: true };
        },
        USER_LOGGING_CANCEL(state, action) {
            return { ...state, authenticating: false };
        },
        USER_LOGGED(state, action) {
            return {
                ...state,
                authenticating: false,
                userLogged: action.payload.user.id,
                user: action.payload.user,
                isAuthenticated: true,
            };
        },
        USER_LOGOUT(state, action) {
            return { ...INITIAL_STATE };
        },
    },
});

export const reducer = slice.reducer;
export default slice;

export const checkAuth = () => async (dispatch, getState) => {
    authApiService.subscribeAuthStatus(async (user) => {
        if (user) {
            const profile = await getWithDispatch(user.uid, dispatch);
            const { roles = [], language = 'en' } = profile;
            if (!roles.includes('admin')) {
                await authApiService.signOut();
                dispatch(slice.actions.USER_LOGOUT());
            }
            changeLanguage(language);
            const userLogged = {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
                language,
                roles,
            };
            dispatch(slice.actions.USER_LOGGED({ user: userLogged }));
        } else dispatch(slice.actions.USER_LOGOUT());
    });
};

export const checkCredentials = (credentials) => async (dispatch) => {
    dispatch(slice.actions.USER_LOGGING());
    const { countryCode, phone: phoneNumber } = credentials;
    const phone = `${countryCode}${phoneNumber}`;
    let verificationId = null;
    try {
        verificationId = await authApiService.verifyPhoneNumber(phone);
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.USER_LOGGING_CANCEL());
    return verificationId;
};

export const signIn = (credentials) => async (dispatch) => {
    let result = null;
    dispatch(slice.actions.USER_LOGGING());
    const { verificationId, verificationCode } = credentials;
    try {
        await authApiService.signInWithPhoneVerification(
            verificationId,
            verificationCode,
        );
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.USER_LOGGING_CANCEL());
    return result;
};

export const signOut = () => async (dispatch) => {
    await authApiService.signOut();
    dispatch(slice.actions.USER_LOGOUT());
};

export const changeAppLanguage = () => async (dispatch, getState) => {
    let { auth } = getState();
    const {
        auth: { userLogged },
        profiles: { byId },
    } = getState();
    const currentProfile =
        userLogged && byId[userLogged] ? byId[userLogged] : {};
    let { language = null } = currentProfile;
    switch (language) {
        case 'es-ES':
            language = 'en-EN';
            break;
        case 'en-EN':
            language = 'es-ES';
            break;
        default:
            break;
    }
    profilesApiService.setProfileLanguage(auth.userLogged, language);
    changeLanguage(language);
};
