import { createSlice } from '@reduxjs/toolkit';
import * as messagesService from 'src/services/messages';

const INITIAL_STATE = {
    loadingThreads: false,
    loadingMessages: false,
    sending: false,
    sendingError: null,
    threadsById: {},
    messagesById: {},
    offset: null,
    total: null,
};

let errorTimeout = null;

// Reducer
const slice = createSlice({
    name: 'messages',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING_THREADS(state, action) {
            state.loadingThreads = true;
        },
        LOADING_MESSAGES(state, action){
            state.loadingMessages = true;
        },
        SENDING(state, action) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
                errorTimeout = null;
            }
            state.sending = true;
        },
        CANCEL(state, action) {
            state.loadingThreads = false;
            state.sending = false;
            state.loadingMessages = false;
        },
        THREADS_OBTAINED(state, action) {
            state.threadsById = {
                ...state.threadsById,
                ...action.payload,
            };
        },
        MESSAGES_OBTAINED(state, action) {
            state.messagesById = {
                ...state.messagesById,
                ...action.payload,
            };
        },
        SENDING_ERROR(state, action) {
            state.sendingError = action.payload;
        },
        RESET(state, action) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
                errorTimeout = null;
            }
            state.doingError = null;
            state.doing = false;
        },
        OBTAINED_PAG(state, action) {
            state.threadsById = {
                ...state.threadsById,
                ...action.payload.byId,
            };
            state.offset = action.payload.offset;
            state.total = action.payload.total;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAllThreads = () => async (dispatch) => {
    dispatch(slice.actions.LOADING_THREADS());
    let all = [];
    try {
        all = await messagesService.getAll();
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((shop) => {
        byId[shop.id] = shop;
    });
    dispatch(slice.actions.THREADS_OBTAINED(byId));
    dispatch(slice.actions.CANCEL());
};

export const get = (id) => async (dispatch) => {
    dispatch(slice.actions.LOADING_THREADS());
    let thread = null;
    try {
        thread = await messagesService.get(id);
    } catch (error) {
        console.error(error);
    }
    if (thread)
        dispatch(slice.actions.THREADS_OBTAINED({ [thread.id]: thread }));
    dispatch(slice.actions.CANCEL());
};

export const getMessages = (id) => async (dispatch) => {
    dispatch(slice.actions.LOADING_MESSAGES());
    let all = [];
    try {
        all = await messagesService.getMessages(id);
        dispatch(slice.actions.MESSAGES_OBTAINED({ [id]: {} }));
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((message) => {
        byId[message.id] = message;
    });
    dispatch(slice.actions.MESSAGES_OBTAINED({ [id]: byId }));
    dispatch(slice.actions.CANCEL());
};

export const sendMessage = (id, text) => async (dispatch) => {
    dispatch(slice.actions.SENDING());
    try {
        await messagesService.sendMessage(id, text);
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.CANCEL());
    return;
};

export const getByLastMessagePag = () => async (dispatch, getState) => {
    const {
        // shops: { currentShop },
        messages: { offset: lastOffset },
    } = getState();
    dispatch(slice.actions.LOADING_THREADS());
    const {
        threads: all,
        offset,
        total,
    } = await messagesService.getByLastMessagePag(lastOffset);
    const byId = {};
    all.forEach((thread) => {
        byId[thread.id] = thread;
    });
    dispatch(
        slice.actions.OBTAINED_PAG({
            byId,
            offset,
            total,
        }),
    );
    dispatch(slice.actions.CANCEL());
};
