import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import * as authActionsSlice from 'src/slices/auth';

import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node,
};
const defaultProps = {};

class ShopGuard extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const { currentShop } = nextProps;
        return { currentShop };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { currentShop } = this.state;
        const { children } = this.props;
        if (!currentShop) {
            return <Redirect to="/working-shop-selector" />;
        }
        return <>{children}</>;
    }
}

ShopGuard.propTypes = propTypes;
ShopGuard.defaultProps = defaultProps;

const mapStateToProps = ({ shops: { currentShop } }) => {
    return {
        currentShop,
    };
};

export default connect(mapStateToProps, {})(ShopGuard);
