import firebase from 'src/lib/firebase';
const db = firebase.firestore();

export const process = async (document) => {
    const { name, address, media = {}, code, status, type = 'storage', gps } = document.data();
    const { thumbnail, svg = null } = media;
    const { city = null, country = null, state = null, street = null, zipCode = null } = address || {};
    const { longitude, latitude } = gps || {};
    return {
        id: document.ref.id,
        name,
        address,
        status,
        thumbnail,
        code,
        city,
        country,
        state,
        street,
        zipCode,
        type,
        longitude,
        latitude,
        // ...document.data(),
        svg,
    };
};

export const getCollectionRef = () => db.collection('shops');
export const getRef = (id) => getCollectionRef().doc(id);

export const getAll = async () => {
    const { docs } = await getCollectionRef().get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};
