import { createSlice } from '@reduxjs/toolkit';
import * as profilesApiService from 'src/services/profiles';

// Redux Actions

const INITIAL_STATE = {
    loading: false,
    loadingHolders: false,
    byId: {},
    profilesFound: [],
    validationsById: {},
    loadingPaymentMethods: false,
    paymentMethodsById: {},
    holdersByProfileId: {},
    offset: null,
    total: null,
};

const slice = createSlice({
    name: 'profiles',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        LOADING_HOLDERS(state, action) {
            state.loadingHolders = true;
        },
        CANCEL(state, action) {
            state.loading = false;
            state.loadingHolders = false;
        },
        OBTAINED_BY_ID(state, action) {
            const byId = action.payload;
            state.byId = { ...state.byId, ...byId };
            state.loading = false;
            // return { ...state, byId: { ...state.byId, ...action.payload } };
        },
        HOLDERS_OBTAINED(state, action) {
            state.holdersByProfileId = {
                ...state.holdersByProfileId,
                ...action.payload,
            };
        },
        PROFILES_FOUND(state, action) {
            state.profilesFound = action.payload;
            state.loading = false;
        },
        PROFILES_FOUND_PAG(state, action) {
            state.byId = {
                ...state.byId,
                ...action.payload.byId,
            };
            state.profilesFound = [
                ...state.profilesFound,
                ...action.payload.profiles,
            ];
            state.offset = action.payload.offset;
            state.total = action.payload.total;
            state.loading = false;
        },
        VALIDATION_BY_ID(state, action) {
            const byId = action.payload;
            state.validationsById = { ...state.validationsById, ...byId };
            state.loading = false;
        },
        PAYMENTMETHODS_BY_ID(state, action) {
            const byId = action.payload;
            state.paymentMethodsById = { ...state.paymentMethodsById, ...byId };
            state.loadingPaymentMethods = false;
        },
        LOADING_PAYMENTMETHODS(state, action) {
            state.loadingPaymentMethods = true;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getWithDispatch = async (
    uid,
    dispatch,
    lastVerificatonParam = false,
    paymentMethodsParam = false,
) => {
    dispatch(slice.actions.LOADING());
    const detail = await profilesApiService.get(
        uid,
        lastVerificatonParam,
        paymentMethodsParam,
    );
    if (detail) {
        dispatch(slice.actions.OBTAINED_BY_ID({ [uid]: detail }));
        return detail;
    }
    return null;
};

export const getLastValidation = (profileId) => async (dispatch) => {
    const lastValidation = await profilesApiService.getLastValidationId(
        profileId,
    );
    dispatch(slice.actions.VALIDATION_BY_ID({ [profileId]: lastValidation }));
};

export const managePaymentMethods = (profileId, action = null) => async (
    dispatch,
) => {
    dispatch(slice.actions.LOADING_PAYMENTMETHODS());
    try {
        let result = null;
        if (action) {
            result = await profilesApiService.managePaymentMethods(
                profileId,
                action,
            );
        }
        const paymentMethods = await profilesApiService.managePaymentMethods(
            profileId,
        );
        dispatch(
            slice.actions.PAYMENTMETHODS_BY_ID({ [profileId]: paymentMethods }),
        );
        return result;
    } catch (error) {
        console.log('Error in managePaymentMethods', error);
    }
};

export const get = (
    uid,
    lastVerificatonParam = false,
    paymentMethodsParam = false,
) => async (dispatch, getState) => {
    const {
        profiles: { byId },
    } = getState();

    return getWithDispatch(
        uid,
        dispatch,
        lastVerificatonParam,
        paymentMethodsParam,
    );
};

export const getAll = () => async (dispatch) => {
    dispatch(slice.actions.LOADING());
    try {
        const all = await profilesApiService.getAll();
        dispatch(slice.actions.PROFILES_FOUND(all));
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const getAllPag = () => async (dispatch, getState) => {
    const {
        profiles: { offset: lastOffset },
    } = getState();
    dispatch(slice.actions.LOADING());
    try {
        const { profiles, offset, total } = await profilesApiService.getAllPag(
            lastOffset,
        );
        const byId = {};
        profiles.forEach((profile) => {
            byId[profile.id] = profile;
        });
        dispatch(
            slice.actions.PROFILES_FOUND_PAG({ byId, profiles, offset, total }),
        );
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const getAllHolders = (profileId) => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING_HOLDERS());
    try {
        const all = await profilesApiService.getAllHolders(profileId);
        dispatch(slice.actions.HOLDERS_OBTAINED({ [profileId]: all }));
        dispatch(slice.actions.CANCEL());

        // Busco los companies porque la función del server no devuelve las companies
        const allCompanies = await profilesApiService.getAllProfileHolders(
            profileId,
        );
        // dispatch(slice.actions.HOLDERS_OBTAINED({ [profileId]: [...all] }));
        dispatch(
            slice.actions.HOLDERS_OBTAINED({
                [profileId]: [...allCompanies, ...all],
            }),
        );
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const getAllProfileHolders = (profileId) => async (dispatch) => {
    dispatch(slice.actions.LOADING_HOLDERS());
    try {
        const all = await profilesApiService.getAllProfileHolders(profileId);
        dispatch(slice.actions.HOLDERS_OBTAINED({ [profileId]: all }));
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const addCompany = (params) => async (dispatch) => {
    try {
        dispatch(slice.actions.LOADING_HOLDERS());
        const newCompany = await profilesApiService.addCompany(params);
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};
