import firebase from 'src/lib/firebase';
import { getRef as getShopRef } from './shops';
import {
    process as processOrder,
    processAll as processAllOrders,
} from './orders';
const db = firebase.firestore();

const serializeTimeline = (id, data) => {
    const {
        type,
        author,
        status,
        times,
        // Referencias
        source: sourceRef = null,
        data: timelineData = {},
        prevData: timelinePrevData = {},
        period = null,
    } = data;

    let source = null;
    if (sourceRef) {
        if (sourceRef.parent.path === 'orders') {
            source = sourceRef.id;
        } else {
            source = sourceRef.parent.parent.id;
        }
    }
    const { startAt = null, endAt = null } = timelineData;
    const { startAt: prevStartAt = null } = timelinePrevData;
    if (startAt) timelineData.startAt = startAt.toDate();
    if (endAt) timelineData.endAt = endAt.toDate();

    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );
    return {
        id,
        type,
        author,
        status,
        times: newTimes,
        data: timelineData,
        period,
        source,
        prevData: { prevStartAt },
    };
};

const processTimelineItem = (itemDoc) => {
    if (!itemDoc.exists) return null;
    const timeline = serializeTimeline(itemDoc.ref.id, itemDoc.data());
    return timeline;
};

const serializeContract = async (id, data) => {
    const {
        code,
        flags,
        status,
        times,
        // Referencias
        profile: profileRef = null,
        shop: shopRef = null,
        space: spaceRef = null,
        thread: threadRef = null,
        holder: holderRef = null,
        // Antiguos contratos timelimePoints
        timelinePoints: timelinePointsOld = null,
    } = data;
    const {
        createdAt = null,
        updatedAt = null,
        startAt = null,
        endAt = null,
    } = times;

    const { renewAtEnd = null, autorenew = null } = flags;
    const timelinePoints = {};
    if (timelinePointsOld) {
        const { last = null, lastPayment = null } = timelinePointsOld;
        // TODO: Si es un contrato antiguo
        timelinePoints.last = last && last.id ? last.id : last;
        timelinePoints.lastPayment =
            lastPayment && lastPayment.id ? lastPayment.id : lastPayment;
    } else {
        // TODO: Buscar últimos timeline de tipo order
    }

    let holder = null;
    if (holderRef && typeof holderRef !== 'string') {
        const holderDoc = await holderRef.get();
        let { name, docNumber, status } = holderDoc.data();
        if (typeof name !== 'string') {
            name = `${name.first || ''}${name.first && name.last ? ' ' : ''}${
                name.last
            }`;
        }
        holder = {
            name,
            docNumber: docNumber.value,
            status,
            path: holderRef.path,
        };
    }

    return {
        id,
        code,
        autorenew,
        renewAtEnd,
        status,
        profileId: profileRef && profileRef.id ? profileRef.id : profileRef,
        shopId: shopRef && shopRef.id ? shopRef.id : shopRef,
        spaceId: spaceRef && spaceRef.id ? spaceRef.id : spaceRef,
        threadId: threadRef && threadRef.id ? threadRef.id : threadRef,
        createdAt: createdAt ? createdAt.toDate() : createdAt,
        updatedAt: updatedAt ? updatedAt.toDate() : updatedAt,
        startAt: startAt ? startAt.toDate() : startAt,
        endAt: endAt ? endAt.toDate() : endAt,
        timelinePoints,
        times: {
            createdAt: createdAt ? createdAt.toDate() : createdAt,
            updatedAt: updatedAt ? updatedAt.toDate() : updatedAt,
            startAt: startAt ? startAt.toDate() : startAt,
            endAt: endAt ? endAt.toDate() : endAt,
        },
        flags,
        holder,
    };
};

export const process = async (document) => {
    if (!document.exists) return null;
    const contract = await serializeContract(document.ref.id, document.data());
    return contract;
};

const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getCollectionRef = () => db.collection('contracts');

export const getRef = (id) => getCollectionRef().doc(id);

export const getAll = async () => {
    const { docs } = await getCollectionRef().get();
    return processAll(docs, process);
};

export const get = async (contractId) => {
    const doc = await getRef(contractId).get();
    return process(doc);
};

export const autorenew = async (contractId) => {
    const autorenewFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsContractsAutorenew');
    return autorenewFunc({ contractId });
};

export const terminate = async (contractId) => {
    const terminateFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsContractsTerminate');
    return terminateFunc({ contractId });
};

export const getTimeline = async (contractId) => {
    const { docs } = await getRef(contractId)
        .collection('timeline')
        .orderBy('times.createdAt', 'asc')
        .get();
    return processAll(docs, processTimelineItem);
};

export const getContractsByProfile = async (shopId, profileId) => {
    if (!shopId) return [];
    const shopRef = getShopRef(shopId);
    const userRef = db.collection('profiles').doc(profileId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .where('profile', '==', userRef)
        .orderBy('times.endAt', 'desc')
        .get();
    return processAll(docs, process);
};

export const getContractsByShop = async (shopId) => {
    const shopRef = db.collection('shops').doc(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .orderBy('times.createdAt', 'asc')
        .get();
    return processAll(docs, process);
};

export const getContractsByShopByCreationPag = async (
    shopId,
    offset = null,
) => {
    if (!shopId) return [];
    const shopRef = db.collection('shops').doc(shopId);
    let query = getCollectionRef()
        .where('shop', '==', shopRef)
        // .where('status', 'in', ['accepted', 'completed', 'executing', 'draft'])
        .orderBy('status')
        // .orderBy('times.createdAt', 'desc');
        .orderBy('times.endAt', 'asc');
    const { docs: totalContracts } = await query.get();
    // if (offset) query = query.startAfter(offset);
    if (offset) query = query.startAt(offset);
    const { docs } = await query.limit(50).get();
    let newOffset = null;
    if (docs.length) {
        // const lastDate = docs[docs.length - 1].data().times.createdAt.toDate();
        // newOffset = lastDate;
        newOffset = docs[docs.length - 1];
    }
    const allPromises = docs.map((doc) => process(doc));
    const contracts = await Promise.all(allPromises);

    return { contracts, offset: newOffset, total: totalContracts.length };

    // const { docs } = await getCollectionRef()
    //     .where('shop', '==', shopRef)
    //     .orderBy('times.createdAt', 'asc')
    //     .get();
    // return processAll(docs, process);
};

export const getContractsBySpace = async (shopId, spaceId) => {
    const spaceRef = db
        .collection('shops')
        .doc(shopId)
        .collection('spaces')
        .doc(spaceId);
    const { docs } = await getCollectionRef()
        .where('space', '==', spaceRef)
        .get();
    return processAll(docs, process);
};

export const addHolder = async (contractId, holder) => {
    const contractAddHolder = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsContractsAddHolder');
    await contractAddHolder({
        contractId,
        holderPath: holder.path ? holder.path : holder,
    });
    return get(contractId);
};

export const remove = async (contractId) => {
    const contractRef = getRef(contractId);
    await contractRef.delete();
    return contractId;
};

export const doAction = async (contractId, action, payload) => {
    const doActionFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsContractsDoAction');
    await doActionFunc({ contractId, action, payload });
    return get(contractId);
};

export const toggleFlag = async (contractId, flag, value) => {
    const doActionFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsContractsToogleFlag');
    await doActionFunc({ contractId, flag, value });
    return get(contractId);
};

export const getNewContractEvents = async (shopId, dateStart, dateEnd) => {
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        // .where('status', '!=', 'finished')
        .where('times.startAt', '>=', dateStart)
        .where('times.startAt', '<=', dateEnd)
        .get();
    return processAll(docs, process);
};

export const getRenewContractEvents = async (shopId, dateStart, dateEnd) => {
    const shopRef = getShopRef(shopId);
    const { docs } = await db
        .collection('orders')
        .where('shop', '==', shopRef)
        .where('status', '==', 'completed')
        .where('times.createdAt', '>=', dateStart)
        .where('times.createdAt', '<=', dateEnd)
        .get();
    const orders = await processAllOrders(docs, processOrder);
    const renewContracts = [];
    const renewContractsCode = [];
    const allPromises = await orders.map(async (order) => {
        const { docs } = await db
            .collection('orders')
            .doc(order.id)
            .collection('items')
            .get();

        const allPromises2 = await docs.map(async (item) => {
            const data = item.data();
            if (data.type === 'renewContractSpace') {
                const { params = {} } = data;
                const { contract = null } = params;
                if (contract) {
                    try {
                        const contractDoc = await contract.get();
                        const data = contractDoc.data();
                        data.renewAt = order.times.createdAt;
                        data.id = contractDoc.ref.id;
                        // const contract = await serializeContract(contractDoc.ref.id, contractDoc.data());
                        // renewContracts.push(data);
                        return data;
                        // return null;
                    } catch (error) {
                        console.log('Error con contrato');
                    }
                }
            }
        });
        const results = await Promise.all(allPromises2);
        // const resultsFiltered = results.filter((element) => {
        //     return element !== null;
        // });

        // renewContracts.push(...results);
        return results;
    });

    const results = await Promise.all(allPromises);
    results.forEach((elements) => {
        elements.forEach((element) => {
            if (element) {
                renewContracts.push(element);
                renewContractsCode.push(element.code);
            }
        });
    });
    const { docs: nextRenew } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .where('status', '==', 'enabled')
        .where('times.endAt', '>=', new Date())
        .where('times.endAt', '<=', dateEnd)
        .get();
    const nextRenewContracts = await processAll(nextRenew, process);
    nextRenewContracts.forEach((element) => {
        if (element) {
            element.renewAt = element.times.endAt;
            element.nextRenewContracts = true;
            // if (renewContractsCode.includes(element.code)) {
            //     console.log("TRACKING YA ESTÁ!!!!!", element.code)
            // }
            renewContracts.push(element);
        }
    });
    return renewContracts;
};

export const getFinishedContractEvents = async (shopId, dateStart, dateEnd) => {
    const shopRef = getShopRef(shopId);
    const { docs } = await getCollectionRef()
        .where('shop', '==', shopRef)
        .where('status', '==', 'finished')
        .where('times.endAt', '>=', dateStart)
        .where('times.endAt', '<=', dateEnd)
        .get();
    return processAll(docs, process);
};
