import { createSlice } from '@reduxjs/toolkit';
import * as ordersService from 'src/services/orders';

const INITIAL_STATE = {
    loading: false,
    doing: false,
    byShopIdById: {},
    loadingOrderItems: false,
    orderItemsById: {},
    ordersByProfileId: {},
    offset: null,
    total: null,
};

const slice = createSlice({
    name: 'orders',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        DOING(state, action) {
            state.doing = true;
        },
        CANCEL(state, action) {
            state.loading = false;
            state.doing = false;
            state.loadingOrderItems = false;
        },
        OBTAINED(state, action) {
            state.byShopIdById = {
                [action.payload.shopId]: {
                    ...state.byShopIdById[action.payload.shopId],
                    ...action.payload.byId,
                },
            };
        },
        OBTAINED_PAG(state, action) {
            state.byShopIdById = {
                [action.payload.shopId]: {
                    ...state.byShopIdById[action.payload.shopId],
                    ...action.payload.byId,
                },
            };
            state.offset = action.payload.offset;
            state.total = action.payload.total;
        },
        LOADING_ORDER_ITEMS(state, action) {
            state.loadingOrderItems = true;
        },
        OBTAINED_ORDER_ITEMS(state, action) {
            state.orderItemsById = {
                ...state.orderItemsById,
                ...action.payload,
            };
        },
        RETRIEVED_ORDERS_BY_PROFILE_ID(state, action) {
            state.ordersByProfileId = {
                ...state.ordersByProfileId,
                ...action.payload,
            };
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    const all = await ordersService.getAll(currentShop);
    const byId = {};
    all.forEach((order) => {
        byId[order.id] = order;
    });
    dispatch(slice.actions.OBTAINED({ shopId: currentShop, byId }));
    dispatch(slice.actions.CANCEL());
};

export const ordersByCreationPag = () => async (dispatch, getState) => {
    const {
        shops: { currentShop },
        orders: { offset: lastOffset },
    } = getState();
    dispatch(slice.actions.LOADING());
    const {
        orders: all,
        offset,
        total,
    } = await ordersService.ordersByCreationPag(currentShop, lastOffset);
    const byId = {};
    all.forEach((order) => {
        byId[order.id] = order;
    });
    dispatch(
        slice.actions.OBTAINED_PAG({
            shopId: currentShop,
            byId,
            offset,
            total,
        }),
    );
    dispatch(slice.actions.CANCEL());
};

export const get = (orderId) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
        orders: { byShopIdById },
    } = getState();
    const orders = byShopIdById[currentShop] || {};
    if (!(orderId in orders)) {
        dispatch(slice.actions.LOADING());
        const order = await ordersService.get(orderId);
        const byId = {};
        byId[orderId] = order;
        dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
        dispatch(slice.actions.CANCEL());
    }
};

const subscriptionsById = {};

export const subscribe = (orderId) => async (dispatch) => {
    if (!subscriptionsById[orderId]) {
        subscriptionsById[orderId] = {
            subscription: ordersService.subscribe(orderId, (order) => {
                const byId = { [orderId]: order };
                dispatch(
                    slice.actions.OBTAINED({ shopId: order.shopId, byId }),
                );
            }),
            count: 1,
        };
    } else {
        subscriptionsById[orderId].count += 1;
    }
};

export const unsubscribe = (orderId) => async (dispatch) => {
    if (subscriptionsById[orderId]) {
        subscriptionsById[orderId].count -= 1;
        if (!subscriptionsById[orderId].count) {
            subscriptionsById[orderId]();
            delete subscriptionsById[orderId];
        }
    }
};

export const select = (id) => async (dispatch) => {
    dispatch(slice.actions.SELECT(id));
};

export const getOrderItems = (orderId) => async (dispatch) => {
    dispatch(slice.actions.LOADING_ORDER_ITEMS());
    let all = [];
    try {
        all = await ordersService.getOrderItems(orderId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((orderItem) => {
        byId[orderItem.id] = orderItem;
    });
    dispatch(slice.actions.OBTAINED_ORDER_ITEMS({ [orderId]: byId }));
    dispatch(slice.actions.CANCEL());
};

export const register = (orderDraft, uid) => async (dispatch) => {
    const order = await ordersService.register(orderDraft, uid);
    const byId = {};
    byId[order.id] = order;
    dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
    dispatch(slice.actions.CANCEL());
    return order;
};

export const doAction = (orderId, action) => async (dispatch) => {
    const order = await ordersService.doAction(orderId, action);
    const byId = {};
    byId[order.id] = order;
    dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
    dispatch(slice.actions.CANCEL());
    return order;
};

export const pay = (orderId, payload) => async (dispatch) => {
    const order = await ordersService.pay(orderId, payload);
    const byId = {};
    byId[order.id] = order;
    dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
    dispatch(slice.actions.CANCEL());
    return order;
};

export const changeHolder = (orderId, holderPath) => async (dispatch) => {
    const order = await ordersService.changeHolder(orderId, holderPath);
    const byId = {};
    byId[order.id] = order;
    dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
    dispatch(slice.actions.CANCEL());
    return order;
};

export const getOrdersByProfile = (profileId) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    let ordersByProfile = [];
    try {
        ordersByProfile = await ordersService.getOrdersByProfile(
            currentShop,
            profileId,
        );
    } catch (error) {
        console.error(error);
    }
    dispatch(
        slice.actions.RETRIEVED_ORDERS_BY_PROFILE_ID({
            [profileId]: ordersByProfile,
        }),
    );
};
