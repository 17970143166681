import firebase from 'src/lib/firebase';
const db = firebase.firestore();

const serializeLastVertification = (verification) => {
    const { fields, status, times } = verification;
    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );
    const newFields = fields;
    if (newFields.birthDate && newFields.birthDate.value) {
        newFields.birthDate.value = newFields.birthDate.value.toDate();
    }
    if (newFields.privacyPolicy && newFields.privacyPolicy.value) {
        newFields.privacyPolicy.value = {
            id: newFields.privacyPolicy.value.id,
            path: newFields.privacyPolicy.value.path,
        };
    }
    return {
        status,
        times,
        fields: newFields,
    };
    // return {
    //     fields,
    //     status,
    //     times: newTimes,
    // };
};

const serializeProfile = (id, data) => {
    const {
        active,
        address,
        demo,
        docNumber,
        language,
        name = null,
        // paymentMethods,
        phone,
        email = null,
        privacyPolicy: privacyPolicyRef = null,
        roles = ['user'],
        status,
        birthDate = null,
        times,
        lastVerification: lastVerificationRef = null,
        profiles = [],
        paymentPlatform = {},
        fullName = '',
    } = data;
    const { createdAt = null, updatedAt = null } = times;
    // let fullName = null;
    // if (name && (name.first || name.last)) {
    //     fullName = `${name.first ? name.first : ''}${
    //         name.first && name.last ? ' ' : ''
    //     }${name.last ? name.last : ''}`;
    // }
    const { stripe = {} } = paymentPlatform;
    const { customerId = null } = stripe;
    return {
        id,
        active,
        address,
        demo,
        docNumber,
        language,
        name,
        firstName: name ? name.first || 'n/a' : 'n/a',
        lastName: name ? name.last || 'n/a' : 'n/a',
        fullName,
        // paymentMethods,
        phone: phone || 'n/a',
        email: email || 'n/a',
        roles,
        status,
        uid: id,
        times: {
            createdAt: createdAt ? createdAt.toDate() : createdAt,
            updatedAt: updatedAt ? updatedAt.toDate() : updatedAt,
        },
        birthDate: birthDate ? birthDate.toDate() : birthDate,
        privacyPolicyId:
            privacyPolicyRef && privacyPolicyRef.id
                ? { id: privacyPolicyRef.id, path: privacyPolicyRef.path }
                : privacyPolicyRef,
        lastVerification:
            lastVerificationRef && lastVerificationRef.id
                ? { id: lastVerificationRef.id, path: lastVerificationRef.path }
                : lastVerificationRef,
        profiles,
        customerId,
    };
};

export const process = async (
    document,
    lastVerificationParam = false,
    paymentMethodsParam = false,
) => {
    const profile = serializeProfile(document.ref.id, document.data());
    let { lastVerification = null } = profile;
    let paymentMethods = null;
    if (paymentMethodsParam)
        paymentMethods = await managePaymentMethods(document.ref.id);
    let serializedLastVerification = {};
    if (lastVerification && lastVerificationParam) {
        const lastVerificationRef = await db
            .collection('profiles')
            .doc(document.ref.id)
            .collection('verifications')
            .doc(lastVerification.id)
            .get();
        const lastVerificationDoc = await lastVerificationRef.data();
        if (lastVerificationDoc) {
            serializedLastVerification = serializeLastVertification(
                lastVerificationDoc,
            );
        }
    }
    const lastValidationId = await getLastValidationId(document.ref.id);

    return {
        ...profile,
        paymentMethods,
        lastVerification: serializedLastVerification,
        lastValidationId,
    };
};

const getLastValidation = async (profileId) => {
    const profileRef = getCollectionRef().doc(profileId);
    const { docs } = await db
        .collection('validations')
        .where('type', '==', 'docNumber')
        .where('document', '==', profileRef)
        .orderBy('times.createdAt', 'desc')
        .limit(1)
        .get();

    return docs.length ? docs[0].ref.id : null;
};

export const getLastValidationId = async (profileId) => {
    return await getLastValidation(profileId);
};

export const managePaymentMethods = async (uid, action = null) => {
    try {
        const managePaymentMethods = firebase
            .functions()
            .httpsCallable('srcOnCallFunctionsStripeManagePaymentMethods');
        const { data } = await managePaymentMethods({ uid, action });
        return data;
    } catch (error) {
        console.log('Error in services managePaymentMethods', error);
    }
};

export const processHolder = async (document) => {
    let { lastVerification } = document.data();
    if (lastVerification)
        lastVerification = (await lastVerification.get()).data();
    return {
        id: document.ref.id,
        ...document.data(),
        lastVerification,
        path: document.ref.path,
    };
};

export const getCollectionRef = () => db.collection('profiles');

const processAll = (docs, processFunction, params = {}) => {
    const {
        searchPhone = null,
        lastVerificationParam = false,
        paymentMethodsParam = false,
    } = params;
    const allPromises = docs.map((doc) => {
        const { phone } = doc.data();
        if (!searchPhone) {
            return processFunction(
                doc,
                lastVerificationParam,
                paymentMethodsParam,
            );
        } else {
            if (phone.startsWith(searchPhone)) {
                return processFunction(
                    doc,
                    lastVerificationParam,
                    paymentMethodsParam,
                );
            }
            return null;
        }
        // return processFunction(doc);
    });
    return Promise.all(allPromises);
};

const processAllHolders = (docs, processFunction, params = {}) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const get = async (
    uid,
    lastVerificationParam = false,
    paymentMethodsParam = false,
) => {
    if (!uid) return null;
    const document = await db
        .collection('profiles')
        .doc(uid)
        .get();
    if (!document.exists) return null;
    return process(document, lastVerificationParam, paymentMethodsParam);
};

export const setProfileLanguage = async (uid, language) => {
    await db
        .collection('profiles')
        .doc(uid)
        .set({ language }, { merge: true });
};

// export const searchProfilesByPhone = async (startText = '') => {
//     const endText = startText.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
//     let { docs } = await getCollectionRef()
//         .where('phone', '>=', '+34' + startText)
//         .get();
//     let results = [];
//     const resultsPhone = await processAll(docs, process, {
//         searchPhone: '+34' + startText,
//     });

//     const docsName = await getCollectionRef()
//         .where('name.first', '>=', startText)
//         .where('name.first', '<', endText)
//         .get();
//     const resultsName = await processAll(docsName.docs, process, {
//         lastVerificationParam: true,
//     });

//     const docsLastName = await getCollectionRef()
//         .where('name.last', '>=', startText)
//         .where('name.last', '<', endText)
//         .get();
//     const resultsLastName = await processAll(docsLastName.docs, process, {
//         lastVerificationParam: true,
//     });
//     results = [...results, ...resultsPhone, ...resultsName, ...resultsLastName];
//     return results.filter((n) => n);
// };

export const getAll = async () => {
    const { docs } = await getCollectionRef().get();
    return processAll(docs, process);
};

export const getAllPag = async (offset = null) => {
    let query = getCollectionRef()
        .where('fullName', '>', '')
        .orderBy('fullName')
        .orderBy('phone');
    // .orderBy('times.createdAt');
    const { docs: totalProfiles } = await query.get();
    if (offset) {
        query = query.startAt(offset);
    }
    const { docs } = await query.limit(70).get();
    let newOffset = null;
    if (docs.length) {
        // const lastDate = docs[docs.length - 1].data().times.createdAt.toDate();
        // newOffset = lastDate;
        newOffset = docs[docs.length - 1];
    }
    const allPromises = docs.map((doc) => process(doc));
    const profiles = await Promise.all(allPromises);
    return { profiles, offset: newOffset, total: totalProfiles.length };
    // const { docs } = await getCollectionRef().get();
    // return processAll(docs, process);
};

export const getAllHolders = async (uid, action = null) => {
    const manageHolders = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsHoldersManage');
    const { data } = await manageHolders({ uid, action });
    // return processAll(docs, process);
    return data;
};

export const getAllProfileHolders = async (uid) => {
    let query = getCollectionRef()
        .doc(uid)
        .collection('companies');
    const { docs } = await query.get();
    return processAllHolders(docs, processHolder);
};

export const createInvitations = async (payload) => {
    try {
        const manageInvitationsFunction = firebase
            .functions()
            .httpsCallable('srcOnCallFunctionsInvitationsManage');
        const result = await manageInvitationsFunction({
            action: 'create',
            payload,
        });
        return result.data;
    } catch (error) {
        console.log('Error in services createInvitations', error);
    }
};

export const addCompany = async (params) => {
    try {
        const { profileId } = params;
        delete params.profileId;
        const resp = await getCollectionRef()
            .doc(profileId)
            .collection('companies')
            .add(params);
        return;
    } catch (error) {
        console.log('Error in services addCompany', error);
    }
};

export default {
    process,
    processHolder,
    get,
    // searchProfilesByPhone,
};
