import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

class ShopTitle extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const { currentShop } = nextProps;
        return { currentShop };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentDidMount() {
    //     const { spacesActions } = this.props;
    //     const { space } = this.state;
    //     if (!space) spacesActions.getAll();
    // }

    render() {
        const { currentShop } = this.state;
        return (
            <Typography
                // onClick={handleOpen}
                variant="h4"
                color="inherit"
                style={{ margin: 5 }}>
                {currentShop.name}
            </Typography>
        );
    }
}

// ShopTitle.propTypes = {
// };

const mapStateToProps = ({
    shops: { currentShop, byId },
    // spaces: { byShopIdById },
}) => {
    return {
        currentShop: byId[currentShop] || {},
        // spacesById: byShopIdById[currentShop] || {},
    };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         spacesActions: bindActionCreators(spacesActionsSlice, dispatch),
//     };
// };

export default connect(mapStateToProps, null)(ShopTitle);
