import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/template/elements/GlobalStyles';
import ScrollReset from 'src/components/template/elements/ScrollReset';
import CookiesNotification from 'src/components/template/elements/CookiesNotification';
import GoogleAnalytics from 'src/components/template/elements/GoogleAnalytics';
// import SettingsNotification from 'src/components/template/elements/SettingsNotification';

// import { AuthProvider } from 'src/contexts/Auth0Context';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
// import { AuthProvider } from 'src/contexts/JWTAuthContext';

import 'src/translations/i18n';

import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
    const { settings } = useSettings();
    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider dense maxSnack={3}>
                        <Router history={history}>
                            <AuthProvider>
                                <GlobalStyles />
                                <ScrollReset />
                                <GoogleAnalytics />
                                <CookiesNotification />
                                {/* <SettingsNotification /> */}
                                {renderRoutes(routes)}
                            </AuthProvider>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
