import firebase from 'src/lib/firebase';
import { getRef as getShopRef } from './shops';
import { getRef as getContractRef } from './contracts';

export const process = async (document) => {
    if (!document.exists) return null;
    const data = document.data();
    let { type = null } = data;
    let { dimensions = null } = data;
    const {
        label,
        category = {},
        media,
        prices,
        qrCode,
        status,
        times = {},
        requireConfirm,
    } = data;
    const {
        color: categoryColor = null,
        label: categoryLabel = null,
        ref: categoryId = null,
    } = category;
    if (!type) type = dimensions ? 'space' : 'commonArea';
    const newTimes = {};
    Object.keys(times).forEach((keyTime) => {
        try {
            return (newTimes[keyTime] = new Date(times[keyTime].toDate()));
        } catch (error) {
            // console.log(
            //     'Error al convertir fechas',
            //     document.ref.id,
            //     keyTime,
            //     times,
            // );
        }
    });
    return {
        id: document.ref.id,
        shopId: document.ref.parent.parent.id,
        spaceId: document.ref.id,
        categoryColor,
        categoryLabel,
        categoryId: categoryId ? categoryId.id : null,
        label,
        type,
        media,
        prices,
        qrCode,
        status,
        times: newTimes,
        requireConfirm,
        dimensions,
        createdAt: times.createdAt ? times.createdAt : null,
        updatedAt: times.updatedAt ? times.updatedAt : null,
    };
};

export const getCollectionRef = (shopId) =>
    getShopRef(shopId).collection('spaces');

export const getRef = (shopId, spaceId) =>
    getCollectionRef(shopId).doc(spaceId);

export const getAll = async (shopId) => {
    if (!shopId) return [];
    const { docs } = await getCollectionRef(shopId).get();
    const allPromises = docs.map((doc) => process(doc));
    return Promise.all(allPromises);
};

export const get = async (shopId, spaceId) =>
    process(await getRef(shopId, spaceId).get());

export const openDoorWithoutLocation = async (shopId, spaceId, reason) => {
    const openDoorFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsSpacesOpenWithoutLocation');
    return openDoorFunc({ shopId, spaceId, reason });
};

export const changeStatus = async (shopId, spaceId, status) => {
    const changeStatusFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsSpacesChangeStatus');
    await changeStatusFunc({ shopId, spaceId, status });
    return get(shopId, spaceId);
};

export const doAction = async (shopId, spaceId, action, payload) => {
    const changeStatusFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsSpacesDoAction');
    await changeStatusFunc({ shopId, spaceId, action, payload });
    return get(shopId, spaceId);
};

export const getSpacesCategories = async (shopId) => {
    const spaces = await getAll(shopId);
    const categories = [];

    spaces.forEach((doc) => {
        const data = doc.data();
        const { category: { label = null } = {} } = data;
        if (label && !categories.includes(label)) {
            categories.push(label);
        }
    });
    return categories.sort();
};

const serializeEvent = (id, data) => {
    const {
        event,
        author = null,
        source = {},
        times,
        // Referencias
        // data: timelineData = {},
    } = data;

    let { ref: sourceRef = null, type, reason = '' } = source;
    if (sourceRef) {
        sourceRef = sourceRef.id;
    }

    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );

    return {
        id,
        source: sourceRef,
        event,
        authorId: author ? author.id : null,
        times: newTimes,
        type,
        reason,
    };
};

const processEventItem = (itemDoc) => {
    if (!itemDoc.exists) return null;
    const event = serializeEvent(itemDoc.ref.id, itemDoc.data());
    return event;
};

const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getEvents = async (shopId, spaceId) => {
    const { docs } = await getRef(shopId, spaceId)
        .collection('events')
        .orderBy('times.at', 'asc')
        .get();
    return processAll(docs, processEventItem);
};

export const getEventsByContract = async (shopId, spaceId, contractId) => {
    const contractRef = getContractRef(contractId);
    const { docs } = await getRef(shopId, spaceId)
        .collection('events')
        .where('source.ref', '==', contractRef)
        .orderBy('times.at', 'asc')
        .get();
    return processAll(docs, processEventItem);
};
