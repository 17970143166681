import { createSlice } from '@reduxjs/toolkit';
import * as validationsApiService from 'src/services/validations';

const INITIAL_STATE = {
    loading: false,
    verifying: false,
    byId: {},
    error: null,
};

const slice = createSlice({
    name: 'validations',
    initialState: INITIAL_STATE,
    reducers: {
        OBTAINING(state, action) {
            state.loading = true;
            state.error = INITIAL_STATE.error;
        },
        OBTAINED(state, action) {
            state.loading = INITIAL_STATE.loading;
            const byId = action.payload;
            state.byId = { ...state.byId, ...byId };
        },
        ERROR(state, action) {
            state.loading = INITIAL_STATE.loading;
            state.error = action.payload;
        },
        VERIFYING_START(state, action) {
            state.verifying = true;
        },
        VERIFYING_END(state, action) {
            state.verifying = INITIAL_STATE.verifying;
        },
    },
});

export const reducer = slice.reducer;
export default slice;


// Action Creators
export const get = (validationId) => async (dispatch) => {
    dispatch(slice.actions.OBTAINING());
    try {
        const response = await validationsApiService.get(validationId);
        if (!response) throw new Error('Unknown validation');
        const data = {};
        data[validationId] = response;
        dispatch(slice.actions.OBTAINED(data));
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.ERROR(error.message));
    }
};

export const doVerification = (validationId, validations) => async (
    dispatch,
) => {
    dispatch(slice.actions.VERIFYING_START());
    const response = await validationsApiService.validate(
        validationId,
        validations,
    );
    dispatch(slice.actions.VERIFYING_END());
    return response;
}
