import { createSlice } from '@reduxjs/toolkit';
import * as shopApiService from 'src/services/shops';

const INITIAL_STATE = {
    loading: false,
    byId: {},
    currentShop: '5cf501d90d3c7f0e6b8cd0f9',
    // TODO: Quitar tienda por defecto
    // currentShop: null,
};

const slice = createSlice({
    name: 'shops',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        CANCEL(state, action) {
            state.loading = false;
        },
        OBTAINED(state, action) {
            const byId = action.payload;
            state.byId = { ...state.byId, ...byId };
            if (process.env.NODE_ENV !== 'production') {
                // Set default shop in development mode
                state.currentShop = '5cf501d90d3c7f0e6b8cd0f9';
            }
        },
        SELECT(state, action) {
            state.currentShop = action.payload;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch) => {
    // dispatch(slice.actions.LOADING());
    const allShops = await shopApiService.getAll();
    const byId = {};
    allShops.forEach((shop) => {
        byId[shop.id] = shop;
    });
    dispatch(slice.actions.OBTAINED(byId));
    // dispatch(slice.actions.CANCEL());
};

export const selectShop = (id) => async (dispatch) => {
    dispatch(slice.actions.SELECT(id));
};
