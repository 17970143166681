const defaultComplete = (baseItem, item) => item;

export const completeListAndDetectUnload = (
    baseList,
    table,
    keyExtractor = (key) => key,
    completeFunction = defaultComplete,
    unLoadKeyExtractor = (key) => key,
) => {
    const unload = [];
    const list = baseList
        .filter((item) => {
            const key = keyExtractor(item);
            if (table[key]) return true;
            unload.push(unLoadKeyExtractor(item));
            return false;
        })
        .map((item) => completeFunction(item, table[keyExtractor(item)]));
    return { list, unload };
};

export const aggrupateByKey = (list, keyExtractor = (item) => item) => {
    const groups = {};
    list.forEach((item) => {
        const key = keyExtractor(item);
        if (!groups[key]) groups[key] = [];
        groups[key].push(item);
    });
    return groups;
};

export const aggrupateById = (list, keyExtractor = ({ id }) => id) => {
    const groups = {};
    list.forEach((item) => {
        const key = keyExtractor(item);
        groups[key] = item;
    });
    return groups;
};
