import { createSlice } from '@reduxjs/toolkit';

import * as authorizationApiService from 'src/services/authorizations';
import { aggrupateById } from 'src/utils/lists';
// import { KEY_TYPE } from 'src/constants/spaces';

// import { KEYS, RESET_KEYS, SIGNOUT } from '../user';

// Redux Actions
export const ADDING = 'trasterox.authorizations.ADDING';
export const LOADING = 'trasterox.authorizations.LOADING';
export const CANCEL = 'trasterox.authorizations.CANCEL';
export const AVAILABLE = 'trasterox.authorizations.AVAILABLE';
export const MY_AVAILABLE = 'trasterox.authorizations.MY_AVAILABLE';

const INITIAL_STATE = {
    loading: false,
    loadingTimeline: false,
    doing: false,
    doingError: null,
    byId: {},
    timelineById: {},
    contractsByProfileId: {},
    authorizationsByContractId: {},
};

let subscription = null;
// Reducer
const slice = createSlice({
    name: 'authorizations',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        ADDING(state, action) {
            state.adding = true;
        },
        CANCEL(state, action) {
            state.adding = true;
            state.loading = true;
        },
        AVAILABLE(state, action) {
            state.byId = { ...state.byId, ...action.payload };
        },
        MY_AVAILABLE(state, action) {
            state.myById = action.payload;
        },
        SIGNOUT(state, action) {
            if (subscription) subscription();
            return INITIAL_STATE;
        },
        OBTAINED_AUTHORIZATION(state, action) {
            state.authorizationsByShopId = {
                ...state.authorizationsByContractId,
                ...action.payload,
            };
        },
        OBTAINED_CONTRACT_AUTHORIZATION(state, action) {
            state.authorizationsByContractId = {
                ...state.authorizationsByContractId,
                ...action.payload,
            };
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING());
    const {
        user: { profile = {} },
    } = getState();
    const { uid = null } = profile;
    try {
        const authorizations = await authorizationApiService.getAll(uid);
        const byId = aggrupateById(authorizations);
        dispatch(slice.actions.AVAILABLE(byId));
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.CANCEL());
};

export const get = (authorizationId) => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING());
    const {
        user: { profile = {} },
    } = getState();
    const { uid = null } = profile;
    try {
        const authorization = await authorizationApiService.get(
            uid,
            authorizationId,
        );
        dispatch(
            slice.actions.AVAILABLE({ [authorization.id]: authorization }),
        );
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.CANCEL());
};

export const register = (authorizationtData) => async (dispatch, getState) => {
    dispatch(slice.actions.ADDING());
    const {
        auth: { userLogged: uid = null },
    } = getState();
    try {
        const authorization = await authorizationApiService.register(
            uid,
            authorizationtData,
        );
        dispatch(
            slice.actions.AVAILABLE({ [authorization.id]: authorization }),
        );
        dispatch(slice.actions.CANCEL());
        return authorization;
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
    }
};

export const toogle = (authorizationId, value) => async (
    dispatch,
    getState,
) => {
    dispatch(slice.actions.LOADING());
    const {
        user: { profile = {} },
        authorizations: { byId },
    } = getState();
    const { uid = null } = profile;
    try {
        dispatch(
            slice.actions.AVAILABLE({
                [authorizationId]: { ...byId[authorizationId], active: value },
            }),
        );
        const authorization = await authorizationApiService.toogle(uid, {
            authorizationId,
            value,
        });
        dispatch(slice.actions.AVAILABLE({ [authorizationId]: authorization }));
    } catch (error) {
        console.error(error);
    }
    dispatch(slice.actions.CANCEL());
};

// const aggrupateMy = (authorizations) => {
//     const byId = {};
//     const keysById = {};
//     authorizations.forEach((authorization) => {
//         const {
//             id,
//             active = true,
//             space,
//             shopId,
//             spaceId,
//             endAt,
//         } = authorization;
//         byId[id] = authorization;
//         const keyId = `${MODEL_NAME}${id}`;
//         keysById[keyId] = {
//             id: keyId,
//             type: KEY_TYPE.authorization,
//             space,
//             shopId,
//             spaceId,
//             endAt,
//             active,
//         };
//     });
//     return { keysById, byId };
// };

// const dispatchAllMy = (authorizations, dispatch, getState) => {
//     const {
//         user: { keysById },
//     } = getState();
//     const keysToReset = Object.keys(keysById).filter((keyId) =>
//         keyId.startsWith(MODEL_NAME),
//     );
//     dispatch(slice.actions.RESET_KEYS(keysToReset));
//     const { keysById: newKeysById, byId } = aggrupateMy(authorizations);
//     dispatch(slice.actions.MY_AVAILABLE(byId));
//     dispatch(slice.actions.KEYS(newKeysById));
// };

// export const subscribeAllMy = async (uid, dispatch, getState) => {
//     if (subscription) {
//         subscription();
//         subscription = null;
//     }
//     subscription = await authorizationApiService.subscribeMy(
//         uid,
//         (authorizations) => dispatchAllMy(authorizations, dispatch, getState),
//     );
// };

// export const getAllMy = () => async (dispatch, getState) => {
//     dispatch(slice.actions.LOADING());
//     const {
//         user: { profile = {} },
//     } = getState();
//     const { uid = null } = profile;
//     try {
//         const authorizations = await authorizationApiService.getAllMy(uid);
//         dispatchAllMy(authorizations, dispatch, getState);
//     } catch (error) {
//         console.error(error);
//     }
//     dispatch(slice.actions.CANCEL());
// };

export const add = (authCode) => async (dispatch, getState) => {
    dispatch(slice.actions.ADDING());
    const {
        user: { profile = {} },
    } = getState();
    const { uid = null } = profile;
    try {
        const authorization = await authorizationApiService.add(uid, authCode);
        dispatch(
            slice.actions.AVAILABLE({ [authorization.id]: authorization }),
        );
    } catch (error) {
        console.error(error);
        dispatch(slice.actions.CANCEL());
        throw error;
    }
    dispatch(slice.actions.CANCEL());
};

export const getAuthorizationsByContract = (contractId) => async (dispatch) => {
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await authorizationApiService.getAuthorizationsByContract(
            contractId,
        );
        const byId = {};
        all.forEach((authorizationItem) => {
            byId[authorizationItem.id] = authorizationItem;
        });
        // dispatch(slice.actions.OBTAINED_AUTHORIZATION({ [contractId]: byId }));
        dispatch(
            slice.actions.OBTAINED_CONTRACT_AUTHORIZATION({
                [contractId]: all,
            }),
        );
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        console.error(error);
    }
};
