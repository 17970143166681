import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/template/screens/SplashScreen';
import firebase from 'src/lib/firebase';
import authApiService from 'src/services/auth';
import store from 'src/store';
import * as authActions from 'src/slices/auth';

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    smsInput: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'AUTH_STATE_CHANGED': {
            const { isAuthenticated, user } = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'FirebaseAuth',
    checkCredentials: () => Promise.resolve(),
    signIn: () => Promise.resolve(),
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithPhoneVerification: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const checkCredentials = async (credentials) => {
        const { countryCode, phone: phoneNumber } = credentials;
        const phone = `${countryCode}${phoneNumber}`;
        let verificationId = null;
        try {
            verificationId = await authApiService.verifyPhoneNumber(phone);
        } catch (error) {
            console.error(error);
        }
        return verificationId;
    };

    const signIn = async (credentials) => {
        let result = null;
        const { verificationId, verificationCode } = credentials;
        try {
            await authApiService.signInWithPhoneVerification(
                verificationId,
                verificationCode,
            );
        } catch (error) {
            console.error(error);
        }
        return result;
    };

    const signInWithEmailAndPassword = (email, password) => {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    };

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const createUserWithEmailAndPassword = async (email, password) => {
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    };

    const logout = () => {
        return firebase.auth().signOut();
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // Here you should extract the complete user profile to make it available in your entire app.
                // The auth state only provides basic information.
                store.dispatch(authActions.checkAuth());
                dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                        isAuthenticated: true,
                        user: {
                            id: user.uid,
                            avatar: user.photoURL,
                            email: user.email,
                            name: user.displayName || user.email,
                        },
                    },
                });
            } else {
                dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        });

        return unsubscribe;
    }, [dispatch]);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }
    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'FirebaseAuth',
                checkCredentials,
                signIn,
                createUserWithEmailAndPassword,
                signInWithEmailAndPassword,
                signInWithGoogle,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
