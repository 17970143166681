import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_EN } from './en/translations';

import moment from 'moment';
import es from 'moment/locale/es';
import en from 'moment/locale/en-ca';

const localizationFiles = {
    'es-ES': es,
    'en-EN': en,
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            es: {
                translation: TRANSLATIONS_ES,
            },
        },
    });

// i18n.changeLanguage('en');

export const strings = i18n.t;

export const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    const localization = localizationFiles[code] || null;
    if (localization) {
        moment.locale(code.substring(0, 2), localization);
    }
};
