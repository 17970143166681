import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';

import { reducer as authReducer } from 'src/slices/auth';
import { reducer as profilesReducer } from 'src/slices/profiles';
import { reducer as shopsReducer } from 'src/slices/shops';
import { reducer as spacesReducer } from 'src/slices/spaces';
import { reducer as contractsReducer } from 'src/slices/contracts';
import { reducer as ordersReducer } from 'src/slices/orders';
import { reducer as authorizationsReducer } from 'src/slices/authorizations';
import { reducer as paymentsReducer } from 'src/slices/payments';
import { reducer as validationsReducer } from 'src/slices/validations';
import { reducer as messagesReducer } from 'src/slices/messages';
import { reducer as invoicesReducer } from 'src/slices/invoices';
import { reducer as dashboardsReducer } from 'src/slices/dashboards';

const rootReducer = combineReducers({
    form: formReducer,
    calendar: calendarReducer,
    chat: chatReducer,
    kanban: kanbanReducer,
    mail: mailReducer,
    notifications: notificationReducer,
    auth: authReducer,
    profiles: profilesReducer,
    shops: shopsReducer,
    spaces: spacesReducer,
    contracts: contractsReducer,
    authorizations: authorizationsReducer,
    orders: ordersReducer,
    payments: paymentsReducer,
    validations: validationsReducer,
    messages: messagesReducer,
    invoices: invoicesReducer,
    dashboards: dashboardsReducer,
});

export default rootReducer;
