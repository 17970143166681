import firebase from 'src/lib/firebase';
import { process as processProfile } from './profiles';

const db = firebase.firestore();

const processMessage = (document) => {
    const { actions = {}, owner = null, text, times } = document.data();
    const newActions = {};
    Object.keys(actions).forEach((action) => {
        newActions[action] = [];
        actions[action].forEach((userAction) => {
            newActions[action].push({
                createdAt: userAction.createdAt.toDate(),
                profile: {
                    id: userAction.profile.id,
                    path: userAction.profile.path,
                },
            });
        });
    });
    const newTimes = {};
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = times[keyTime].toDate()),
    );
    return {
        id: document.ref.id,
        text,
        owner: owner ? { path: owner.path, id: owner.id } : null,
        actions: newActions,
        times: newTimes,
    };
};
const getParticipantData = async (participant) => {
    if (participant !== 'platform') {
        const participantDoc = await participant.get();
        try {
            const participantSerilized = await processProfile(participantDoc);
            const {
                id,
                phone = 'n/a',
                email = 'n/a',
                firstName = 'n/a',
                lastName = 'n/a',
                roles = [],
                status = 'n/a',
                demo = false,
            } = participantSerilized;
            return {
                id,
                phone,
                email,
                firstName,
                lastName,
                roles,
                status,
                demo,
            };
        } catch (error) {
            return null;
        }
    }
};

export const process = async (document) => {
    if (!document.exists) return null;
    const {
        title,
        times = {},
        lastMessage: lastMessageRef = null,
        participants: threadParticipants = [],
    } = document.data();
    const allPromises = threadParticipants.map((participant) =>
        getParticipantData(participant),
    );
    const participants = await Promise.all(allPromises);
    var participantsFiltered = participants.filter((element) => {
        return element != null;
    });
    const newTimes = {};
    let lastMessageId = null;
    if (lastMessageRef) lastMessageId = lastMessageRef.id;
    Object.keys(times).forEach(
        (keyTime) => (newTimes[keyTime] = new Date(times[keyTime].toDate())),
    );
    return {
        id: document.ref.id,
        title,
        times: newTimes,
        date: newTimes.lastMessageAt
            ? newTimes.lastMessageAt
            : newTimes.createdAt || new Date(),
        lastMessageId,
        participants: participantsFiltered,
    };
};

const processAll = (docs, processFunction) => {
    const allPromises = docs.map((doc) => processFunction(doc));
    return Promise.all(allPromises);
};

export const getCollectionRef = () => db.collection('messageThreads');
export const getRef = (id) => getCollectionRef().doc(id);

export const getAll = async () => {
    const { docs } = await getCollectionRef().orderBy('times.createdAt', 'asc').get();
    return processAll(docs, process);
};

export const get = async (id) => {
    const doc = await getRef(id).get();
    return process(doc);
};

export const sendMessage = async (threadId, text) => {
    const sendMessageFunc = firebase
        .functions()
        .httpsCallable('srcOnCallFunctionsThreadsMessagesCreate');
    return sendMessageFunc({ threadId, message: { text } });
};

export const getMessages = async (id) => {
    const { docs } = await getRef(id)
        .collection('messages')
        .orderBy('times.createdAt', 'asc')
        .get();
    return processAll(docs, processMessage);
};

export const getByLastMessagePag = async (offset = null) => {
    // if (!shopId) return [];
    // const shopRef = getShopRef(shopId);
    let query = getCollectionRef()
        // .where('shop', '==', shopRef)
        // .where('status', 'in', ['accepted', 'completed', 'executing', 'draft'])
        .orderBy('times.lastMessageAt', 'desc');
    const { docs: total } = await query.get();
    // if (offset) query = query.startAfter(offset);
    if (offset) query = query.startAfter(offset);
    const { docs } = await query.limit(50).get();
    let newOffset = null;
    if (docs.length) {
        newOffset = docs[docs.length - 1].data().times.lastMessageAt.toDate();
    }
    const allPromises = docs.map((doc) => process(doc));
    const threads = await Promise.all(allPromises);
    return { threads, offset: newOffset, total: total.length };
};
