import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/template/elements/Logo';
// import { THEMES } from 'src/constants';
import Account from './Account';
// import Contacts from './Contacts';
import Shops from './Shops';
import ShopTitle from './ShopTitle';
import Notifications from './Notifications';
// import Search from './Search';
// import Settings from './Settings';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            zIndex: theme.zIndex.drawer + 100,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main,
            // ...theme.name === THEMES.LIGHT ? {
            //     boxShadow: 'none',
            //     backgroundColor: theme.palette.primary.main,
            // } : {},
            // ...theme.name === THEMES.ONE_DARK ? {
            // backgroundColor: theme.palette.background.default
            // } : {}
        },
        toolbar: {
            minHeight: 64,
        },
    };
});

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                    <Hidden xsDown>
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                        <ShopTitle />
                    </Hidden>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo />
                    </RouterLink>
                    <ShopTitle />
                </Hidden>
                <Box ml={2} flexGrow={1} />
                <Shops />
                {/* <Search /> */}
                {/* <Contacts /> */}
                <Notifications />
                {/* <Settings /> */}
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
};

export default TopBar;
