import { createSlice } from '@reduxjs/toolkit';
import * as spacesApiService from 'src/services/spaces';

const INITIAL_STATE = {
    loading: false,
    opening: false,
    doing: false,
    openingError: null,
    byShopIdById: {},
    byShopIdByVolume: {},
    // byShopIdByCategory: {},
    selected: null,
    byShopIdSummary: {},
    eventsById: {},
    eventsByContractId: {},
    getAllObtained: false,
};

let errorTimeout = null;

const slice = createSlice({
    name: 'spaces',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        DOING(state, action) {
            state.doing = true;
        },
        OPENING(state, action) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
                errorTimeout = null;
            }
            state.opening = true;
            state.openingError = null;
        },
        OPENING_ERROR(state, action) {
            state.opening = false;
            state.openingError = action.payload;
        },
        CANCEL(state, action) {
            state.loading = false;
            state.opening = false;
            state.doing = false;
            state.openingError = null;
        },
        OBTAINED(state, action) {
            state.byShopIdById = {
                [action.payload.shopId]: {
                    ...(state.byShopIdById[action.payload.shopId] || {}),
                    ...action.payload.byId,
                },
            };
            state.byShopIdSummary = {
                [action.payload.shopId]: {
                    ...(state.byShopIdSummary[action.payload.shopId] || {}),
                    byVolume: action.payload.byVolume,
                    byCategory: action.payload.byCategory,
                    totalSpaces: action.payload.totalSpaces,
                    totalArea: action.payload.totalArea,
                    totalVolume: action.payload.totalVolume,
                },
            };
            state.getAllObtained = true;
            // console.log("SUMMARY", action.payload.byVolume, action.payload.byCategory)
            // state.byShopIdSummary[action.payload.shopId].byVolume = {
            //     ...action.payload.byVolume,
            // };
            // state.byShopIdSummary[action.payload.shopId].byCategory = {
            //     ...action.payload.byCategory,
            // };
            // console.log("STATE SUMMARY", state.byShopIdSummary)
        },
        SELECT(state, action) {
            state.selected = action.payload;
        },
        OBTAINED_EVENTS(state, action) {
            state.eventsById = {
                ...state.eventsById,
                ...action.payload,
            };
        },
        OBTAINED_EVENTS_BY_CONTRACT(state, action) {
            state.eventsByContractId = {
                ...state.eventsByContractId,
                ...action.payload,
            };
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch, getState) => {
    const {
        shops: { currentShop },
        spaces: { getAllObtained },
    } = getState();
    if (getAllObtained) return;
    dispatch(slice.actions.LOADING());
    const all = await spacesApiService.getAll(currentShop);
    const byId = {};
    const byVolume = {};
    const byCategory = {};
    let totalSpaces = 0;
    let totalVolume = 0;
    let totalArea = 0;
    all.forEach((space) => {
        byId[space.id] = space;
        if (space.type === 'space') {
            totalSpaces += 1;
            totalVolume += space.dimensions.volume;
            totalArea += space.dimensions.area;
            if (!byVolume[Math.ceil(space.dimensions.volume)]) {
                byVolume[Math.ceil(space.dimensions.volume)] = [space.id];
            } else {
                byVolume[Math.ceil(space.dimensions.volume)].push(space.id);
            }
            if (!byCategory[space.categoryId]) {
                byCategory[space.categoryId] = {
                    id: space.categoryId,
                    label: space.categoryLabel,
                    color: space.categoryColor,
                    spaces: [space.id],
                    totalVolume: space.dimensions.volume,
                    totalArea: space.dimensions.area,
                };
            } else {
                byCategory[space.categoryId].spaces.push(space.id);
                byCategory[space.categoryId].totalVolume += space.dimensions.volume;
                byCategory[space.categoryId].totalArea += space.dimensions.area;
            }
        }
    });
    dispatch(
        slice.actions.OBTAINED({
            shopId: currentShop,
            byId,
            byVolume,
            byCategory,
            totalSpaces,
            totalArea,
            totalVolume,
        }),
    );
    dispatch(slice.actions.CANCEL());
};

export const get = (spaceId) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
        spaces: { byShopIdByVolume },
    } = getState();
    dispatch(slice.actions.LOADING());
    const space = await spacesApiService.get(currentShop, spaceId);
    const byId = {};
    byId[spaceId] = space;
    let byVolume = { ...byShopIdByVolume[currentShop] } || null;
    if (space.type === 'space') {
        if (!byVolume[Math.ceil(space.dimensions.volume)]) {
            byVolume[Math.ceil(space.dimensions.volume)] = [space.id];
        } else {
            byVolume[Math.ceil(space.dimensions.volume)].push(space.id);
        }
    }
    dispatch(slice.actions.OBTAINED({ shopId: currentShop, byId, byVolume }));
    dispatch(slice.actions.CANCEL());
};

export const select = (id) => async (dispatch) => {
    dispatch(slice.actions.SELECT(id));
};

export const openDoor = (spaceId, reason) => async (dispatch, getState) => {
    const {
        spaces: { opening },
        shops: { currentShop },
    } = getState();
    if (opening) throw new Error('Acting another door');
    dispatch(slice.actions.OPENING());
    try {
        const result = await spacesApiService.openDoorWithoutLocation(
            currentShop,
            spaceId,
            reason,
        );
        const {
            data: { interval = 0 },
        } = result;
        const promise = new Promise((resolve) =>
            setTimeout(() => {
                dispatch(slice.actions.CANCEL());
                resolve(result);
            }, interval * 1000),
        );
        return promise;
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.OPENING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.CANCEL()), 5000);
        // console.error(error);
        return null;
    }
};

export const changeStatus = (spaceId, status) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.DOING());
    try {
        const space = await spacesApiService.changeStatus(
            currentShop,
            spaceId,
            status,
        );
        dispatch(
            slice.actions.OBTAINED({
                shopId: currentShop,
                byId: { [spaceId]: space },
            }),
        );
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.OPENING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.CANCEL()), 5000);
        console.error(error);
    }
};

export const doAction = (spaceId, action, payload) => async (dispatch, getState) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.DOING());
    try {
        const space = await spacesApiService.doAction(
            currentShop,
            spaceId,
            action,
            payload,
        );
        dispatch(
            slice.actions.OBTAINED({
                shopId: currentShop,
                byId: { [spaceId]: space },
            }),
        );
        dispatch(slice.actions.CANCEL());
    } catch (error) {
        const { message } = error;
        dispatch(slice.actions.OPENING_ERROR(message));
        errorTimeout = setTimeout(() => dispatch(slice.actions.CANCEL()), 5000);
        console.error(error);
    }
};

export const getEvents = (shopId, spaceId) => async (dispatch) => {
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await spacesApiService.getEvents(shopId, spaceId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((item) => {
        byId[item.id] = item;
    });
    dispatch(slice.actions.OBTAINED_EVENTS({ [spaceId]: byId }));
    dispatch(slice.actions.CANCEL());
};

export const getEventsByContract = (shopId, spaceId, contractId) => async (dispatch) => {
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await spacesApiService.getEventsByContract(shopId, spaceId, contractId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((item) => {
        byId[item.id] = item;
    });
    dispatch(slice.actions.OBTAINED_EVENTS_BY_CONTRACT({ [contractId]: byId }));
    dispatch(slice.actions.CANCEL());
};
