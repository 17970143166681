export const TRANSLATIONS_ES = {
    happening: 'Situación actual',
    signIn: 'Identificación',
    trasteroxPlatform: 'plataforma Trasterox',
    auth: {
        sendSmsCode: 'enviar código por SMS',
        validateCode: 'validate code',
        smsCode: 'código SMS',
        invalidPhone: 'Phone number is not valid',
        requiredPhone: 'el número de teléfono es requerido',
        maxCodeLength: 'El código sms debe tener 6 dígitos',
        minCodeLength: 'El código sms debe tener 6 dígitos',
        requiredSMS: 'el código SMS es requerido',
        requiredCountryCode: 'el código de país es requerido',
        smsSent:
            'Te hemos enviado un código SMS, por favor, introducelo para continuar.',
    },
    phone: 'teléfono',
    countryCode: 'código de país',
    reports: 'informes',
    management: 'gestión',
    dashboard: 'cuadro de Mando',
    schedule: 'agenda',
    orders: 'pedidos',
    order: {
        order: 'pedido',
        orderInfo: 'información del Pedido',
        accept: 'aceptar',
        acceptOrder: 'acceptar pedido',
        acceptOrderQuestion: 'quieres aceptar el pedido',
        cancelOrder: 'cancelar pedido',
        cancelOrderQuestion: 'quieres cancelar el pedido',
        executeOrder: 'ejecutar pedido',
        executeOrderQuestion: 'quieres ejecutar el pedido',
        doInvoice: 'hacer factura',
        doInvoiceQuestion: 'Quieres facturar el pedido',
        markAsCompleted: 'marcar como completado',
        markAsCompletedQuestion: 'Quieres marcar como completado el pedido',
        noItems: 'no hay items',
        vat: 'IVA',
        items: 'items',
    },
    contracts: 'contratos',
    contractObject: 'contrato',
    contract: {
        contract: 'contratar',
        contractInfo: 'información del Contrato',
        holder: 'titular',
        holders: 'titulares',
        now: 'contratar ahora',
        space: 'contratar espacio',
        unlockContract: 'desbloquear Contrato',
        unlockContractQuestion: '¿Quiere desbloquear el contrato',
        lockContract: 'bloquear Contrato',
        lockContractQuestion: '¿Quiere bloquear el contrato',
        renewContract: 'renovar Contrato',
        renewContractQuestion: '¿Quiere renovar el contrato {{code}} por {{total}} € que finalizará el ',
        finishContract: 'finalizar Contrato',
        finishContractQuestion: '¿Quiere finalizar el contrato',
        removeContract: 'eliminar Contrato',
        removeContractQuestion: '¿Quiere eliminar el contrato',
        autorenew: 'autorenovar',
        renewAtEnd: 'renovar al final',
    },
    invoices: 'facturas',
    invoice: {
        invoice: 'factura',
        invoiceInfo: 'información de la factura',
        syncWithZoho: 'sincronizar con Zoho',
        syncWithZohoQuestion: '¿Quieres sincronizar la factura con Zoho?',
        invoiceNumber: 'número de factura',
    },
    customers: 'clientes',
    customer: 'cliente',
    messages: 'mensajes',
    payments: 'pagos',
    payment: {
        payReintent:
            '¿Quieres reintentar el pago de {{amount}}€ por el concepto "{{concept}}"?',
        createPayment: 'Crear pago',
        cancelLastPayment: 'cancelar el último intento de pago',
        stripePaymentMethod: 'Método de pago Stripe',
        paymentMethod: 'Método de pago',
        intents: 'intentos',
        defaultConcept: 'pago de pedido a trasterox',
        paymentInfo: 'información del pago',
        paymentDetails: 'detalles del pago',
        lastPaymentIntent: 'último intento de pago',
        nextAction: 'acción adicional',
    },
    pay: 'pagar',
    profiles: 'perfiles',
    profilesNotFound: 'perfiles no encontrados',
    profileSelect: 'seleccione un cliente',
    spaces: 'espacios',
    space: {
        space: 'espacio',
        dimensions: 'dimensiones',
        area: 'area',
        volume: 'volumen',
        prices: 'precios',
        category: 'categoría',
    },
    status: 'estado',
    type: 'tipo',
    spaceInfo: 'information del espacio',
    details: 'detalles',
    day: 'día',
    week: 'semana',
    month: 'mes',
    year: 'año',
    periodSelect: 'seleccione un periodo',
    code: 'código',
    startAt: 'inicio',
    endAt: 'fin',
    createdAt: 'creado',
    updatedAt: 'actualizado',
    openDoor: 'abrir Puerta',
    maintenance: 'mantenimiento',
    changeToMaintenance: 'cambiar a mantenimiento',
    changeToMaintenanceQuestion:
        '¿Está seguro de cambiar el estado a mantenimiento?',
    free: 'libre',
    changeToFree: 'cambiar a libre',
    changeToFreeQuestion: '¿Está seguro de cambiar el estado a libre?',
    rented: 'alquilado',
    changeToRented: 'cambiar a alquilado',
    changeToRentedQuestion: '¿Está seguro de cambiar el estado a alquilado?',
    totalAmount: 'cantidad total',
    close: 'cerrar',
    profile: {
        profileInfo: 'Información del perfil',
        goToProfile: 'ir al perfil',
        phoneCall: 'llamar por teléfono',
        sendWhatsapp: 'enviar un Whatsapp',
        name: 'nombre',
        lastValidation: 'última validación',
        paymentMethods: 'métodos de pago',
        cardBrand: 'compañía',
        cardNumber: 'número de tarjeta',
        expDate: 'fecha de caducidad',
        addPaymentMethod: 'añadir método de pago',
        addCompany: 'añadir Titular',
        addInvitation: 'añadir invitación',
        accessData: 'datos de acceso',
        email: 'email',
        privacyPolicy: 'política de privacidad',
        language: 'idioma',
        roles: 'roles',
        personalData: 'datos personales',
        surname: 'apellidos',
        docNumber: 'DNI/NIE',
        birthdate: 'fecha de nacimiento',
        address: 'domicilio',
        street: 'calle',
        city: 'ciudad',
        zipCode: 'código postal',
        state: 'provincia',
        country: 'país',
        noValidation: 'no existe validación',
        company: 'company',
        companyDocNumber: 'NIF',
        invitationCreated: 'Invitación creada',
    },
    description: 'descripción',
    unitPrice: 'precio unitario',
    quantity: 'cantidad',
    subTotal: 'subtotal',
    totalLine: 'total línea',
    total: 'total',
    tax: 'impuestos',
    cancel: 'cancelar',
    date: 'fecha',
    action: 'acción',
    amount: 'cantidad',
    concept: 'concepto',
    block: 'bloquear',
    unblock: 'desbloquear',
    finish: 'finalizar',
    renew: 'renovar',
    authorizations: 'autorizaciones',
    timeline: 'timeline',
    timelineChrono: 'timeline cronológico',
    goToMessages: 'ir a los mensajes',
    warnings: 'avisos',
    active: 'activo',
    add: 'añadir',
    threads: 'hilos',
    messageThreads: 'hilos de mensaje',
    available: 'disponible',
    access: 'acceso',
    service: 'servicio',
    finished: 'finalizado',
    enabled: 'activado',
    blocked: 'bloqueado',
    notVat: 'sin IVA',
    completed: 'completado',
    accepted: 'aceptado',
    draft: 'borrador',
    cancelled: 'cancelado',
    failed: 'fallido',
    copyLink: 'copiar enlace',
    webPayment: 'pago web',
    showErrorMessage: 'ver errores',
    paid: 'pagado',
    registered: 'registrado',
    warning: 'aviso',
    start: 'inicio',
    registering: 'registrándose',
    checking: 'comprobando',
    applicated: 'aplicado',
    events: 'eventos',
    unlockRequest: 'solicitud de apertura',
    admin: 'administrador',
    authorization: {
        new: 'nueva autorización',
    },
    occupationPlan: 'Plano de Ocupación',
    loadingMore: 'cargando más',
    addCard: 'añadir tarjeta',
    remove: 'eliminar',
};
