/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    // Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    // Link,
    List,
    ListSubheader,
    makeStyles,
    // SvgIcon,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import EventNoteIcon from '@mui/icons-material/EventNote';
// import PaymentIcon from '@material-ui/icons/Payment';

import {
    // Briefcase as BriefcaseIcon,
    // Calendar as CalendarIcon,
    // ShoppingCart as ShoppingCartIcon,
    // Folder as FolderIcon,
    // BarChart as BarChartIcon,
    // Lock as LockIcon,
    // UserPlus as UserPlusIcon,
    // AlertCircle as AlertCircleIcon,
    // Trello as TrelloIcon,
    // User as UserIcon,
    // Layout as LayoutIcon,
    // Edit as EditIcon,
    // DollarSign as DollarSignIcon,
    CreditCard as CreditCardIcon,
    Mail as MailIcon,
    // MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    // Share2 as ShareIcon,
    Users as UsersIcon,
} from 'react-feather';
// import { ShopTwo as ShopsIcon } from '@material-ui/icons';
import Logo from 'src/components/template/elements/Logo';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import ShopTitle from '../TopBar/ShopTitle';
import { capitalize, strings } from 'src/utils/strings';

const sections = [
    {
        subheader: capitalize(strings('reports')),
        items: [
            {
                title: capitalize(strings('dashboard')),
                icon: PieChartIcon,
                // href: '/app/reports/dashboard',
                href: '/',
            },
            {
                title: capitalize(strings('schedule')),
                icon: EventNoteIcon,
                // href: '/app/reports/dashboard',
                href: '/app/schedule',
            },
            {
                title: capitalize(strings('occupationPlan')),
                icon: MapsHomeWorkIcon,
                // href: '/app/reports/dashboard',
                href: '/app/occupationPlan',
            },
            // {
            //     title: 'Dashboard Alternative',
            //     icon: BarChartIcon,
            //     href: '/app/reports/dashboard-alternative',
            // },
        ],
    },
    {
        subheader: capitalize(strings('management')),
        // eslint-disable-next-line
        items: [
            // {
            //     title: 'Shops',
            //     // icon: UsersIcon,
            //     icon: () => {
            //         return (
            //             <SvgIcon fontSize="small" style={{ marginRight: 10 }}>
            //                 <ShopsIcon />
            //             </SvgIcon>
            //         );
            //     },
            //     href: '/app/management/shops',
            //     items: [
            //         {
            //             title: 'Select a working Shop',
            //             href: '/app/management/shops/select-working-shop',
            //         },
            //         {
            //             title: 'List Shops',
            //             href: '/app/management/shops',
            //         },
            //         // {
            //         //     title: 'View Customer',
            //         //     href: '/app/management/customers/1',
            //         // },
            //         // {
            //         //     title: 'Edit Customer',
            //         //     href: '/app/management/customers/1/edit',
            //         // },
            //     ],
            // },
            {
                title: capitalize(strings('spaces')),
                icon: AccountTreeIcon,
                href: '/app/management/spaces',
                // items: [
                //     {
                //         title: 'List Spaces',
                //         href: '/app/management/spaces',
                //     },
                // ],
            },
            {
                title: capitalize(strings('orders')),
                icon: ShoppingBasketIcon,
                href: '/app/management/orders',
            },
            {
                title: capitalize(strings('contracts')),
                icon: AssignmentIcon,
                href: '/app/management/contracts',
            },
            {
                title: capitalize(strings('invoices')),
                icon: ReceiptIcon,
                href: '/app/management/invoices',
            },
            {
                title: capitalize(strings('customers')),
                icon: UsersIcon,
                href: '/app/management/profiles',
            },
            {
                title: capitalize(strings('messages')),
                icon: MailIcon,
                href: '/app/management/messages',
            },
            ,
            {
                title: capitalize(strings('payments')),
                icon: CreditCardIcon,
                href: '/app/management/payments',
            },
        ],
    },
];

function renderNavItems({ items, pathname, depth = 0 }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth }),
                [],
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false,
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}>
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>,
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />,
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    // const { user } = useAuth();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column">
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                        <ShopTitle />
                    </Box>
                </Hidden>
                {/* <Box p={2}>
                    <Box display="flex" justifyContent="center">
                        <RouterLink to="/app/account">
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={user && user.avatar ? user.avatar : null}
                            />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link
                            component={RouterLink}
                            to="/app/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none">
                             TODO: {user.name}
                            {'Anonymous'}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                            Your tier:{' '}
                            <Link component={RouterLink} to="/pricing">
                                {user.tier}
                            </Link>
                        </Typography>
                    </Box>
                </Box> */}
                <Divider />
                <Box p={2}>
                    {sections.map((section) => (
                        <List
                            key={section.subheader}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            }>
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname,
                            })}
                        </List>
                    ))}
                </Box>
                {/* <Divider />
                <Box p={2}>
                    <Box
                        p={2}
                        borderRadius="borderRadius"
                        bgcolor="background.dark">
                        <Typography variant="h6" color="textPrimary">
                            Need Help?
                        </Typography>
                        <Link
                            variant="subtitle1"
                            color="secondary"
                            component={RouterLink}
                            to="/docs">
                            Check our docs
                        </Link>
                    </Box>
                </Box> */}
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
