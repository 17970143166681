import firebase from 'src/lib/firebase';

const verifyPhoneNumber = async (phoneNumber) => {
    const applicationVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
    );
    const provider = new firebase.auth.PhoneAuthProvider();
    const result = await provider.verifyPhoneNumber(
        phoneNumber,
        applicationVerifier,
    );
    return result;
};

const signInWithPhoneVerification = (verificationId, verificationCode) => {
    return firebase
        .auth()
        .signInWithCredential(
            firebase.auth.PhoneAuthProvider.credential(
                verificationId,
                verificationCode,
            ),
        );
}

const signOut = () => firebase.auth().signOut();

const subscribeAuthStatus = (callback) =>
    firebase.auth().onAuthStateChanged(callback);

export default {
    verifyPhoneNumber,
    signInWithPhoneVerification,
    signOut,
    subscribeAuthStatus,
};
