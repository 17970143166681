export const auth0Config = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
// };

let config = null;

// if (process.env.NODE_ENV === 'development') {
    // config = {
    //     apiKey: 'AIzaSyBMvwSHdko7tuRIUFo_EAQ74OqrYTSGRno',
    //     authDomain: 'trasterox-dev.firebaseapp.com',
    //     databaseURL: 'https://trasterox-dev.firebaseio.com',
    //     projectId: 'trasterox-dev',
    //     storageBucket: 'trasterox-dev.appspot.com',
    //     messagingSenderId: '472530107918',
    //     appId: '1:472530107918:web:a02fd40d09c64a045c065f',
    //     measurementId: 'G-TV54ZXR4D4',
    // };
// } else {
    // Production
    config = {
        apiKey: 'AIzaSyB3qOzGp8AaKmXUN4sC_MtmTthd4OXqlWk',
        authDomain: 'trasterox-app.firebaseapp.com',
        databaseURL: 'https://trasterox-app.firebaseio.com',
        projectId: 'trasterox-app',
        storageBucket: 'trasterox-app.appspot.com',
        messagingSenderId: '12543061198',
        appId: '1:12543061198:web:1b61abdde483c8b383b49f',
    };
// }
// const storageRef = firebase.storage().ref();

export const firebaseConfig = config;
