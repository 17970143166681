import { createSlice } from '@reduxjs/toolkit';
import * as invoicesApiService from 'src/services/invoices';

// Redux Actions
const INITIAL_STATE = {
    loading: false,
    byId: {},
    invoicesByProfileId: {},
    byShopIdById: {},
    offset: null,
    total: null,
};

const slice = createSlice({
    name: 'invoices',
    initialState: INITIAL_STATE,
    reducers: {
        LOADING(state, action) {
            state.loading = true;
        },
        CANCEL(state, action) {
            state.loading = false;
        },
        OBTAINED(state, action) {
            const byId = { ...state.byId, ...action.payload };
            state.byId = byId;
        },
        RETRIEVED_INVOICES_BY_PROFILE_ID(state, action) {
            state.invoicesByProfileId = {
                ...state.invoicesByProfileId,
                ...action.payload,
            };
        },
        OBTAINED_PAG(state, action) {
            state.byShopIdById = {
                [action.payload.shopId]: {
                    ...state.byShopIdById[action.payload.shopId],
                    ...action.payload.byId,
                },
            };
            state.offset = action.payload.offset;
            state.total = action.payload.total;
        },
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getAll = () => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING());
    const {
        shops: { currentShop },
    } = getState();
    let all = [];
    try {
        all = await invoicesApiService.getAll(currentShop);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((shop) => {
        byId[shop.id] = shop;
    });
    dispatch(slice.actions.OBTAINED(byId));
    dispatch(slice.actions.CANCEL());
};

export const getById = (invoiceId) => async (dispatch, getState) => {
    dispatch(slice.actions.LOADING());
    let all = [];
    try {
        all = await invoicesApiService.getById(invoiceId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((invoice) => {
        byId[invoice.id] = invoice;
    });
    dispatch(slice.actions.OBTAINED(byId));
    dispatch(slice.actions.CANCEL());
};

export const getByCreationPag = () => async (dispatch, getState) => {
    const {
        shops: { currentShop },
        invoices: { offset: lastOffset },
    } = getState();
    dispatch(slice.actions.LOADING());
    const {
        invoices: all,
        offset,
        total,
    } = await invoicesApiService.getByCreationPag(currentShop, lastOffset);
    const byId = {};
    all.forEach((invoice) => {
        byId[invoice.id] = invoice;
    });
    dispatch(
        slice.actions.OBTAINED_PAG({
            shopId: currentShop,
            byId,
            offset,
            total,
        }),
    );
    dispatch(slice.actions.CANCEL());
};

export const syncWithZoho = (invoiceId) => async (dispatch, getState) => {
    await invoicesApiService.syncWithZoho(invoiceId);
};

export const getInvoicesByProfile = (profileId) => async (
    dispatch,
    getState,
) => {
    const {
        shops: { currentShop },
    } = getState();
    dispatch(slice.actions.LOADING());
    let invoicesByProfile = [];
    try {
        invoicesByProfile = await invoicesApiService.getInvoicesByProfile(
            currentShop,
            profileId,
        );
    } catch (error) {
        console.error(error);
    }
    dispatch(
        slice.actions.RETRIEVED_INVOICES_BY_PROFILE_ID({
            [profileId]: invoicesByProfile,
        }),
    );
};
