import { createSlice } from '@reduxjs/toolkit';
import * as paymentsService from 'src/services/payments';

const INITIAL_STATE = {
    loading: false,
    doing: false,
    loadingOrderPayments: false,
    orderPaymentsById: {},
    paymentsById: {},
    intentsById: {},
};

const slice = createSlice({
    name: 'payments',
    initialState: INITIAL_STATE,
    reducers: {
        // LOADING(state, action) {
        //     state.loading = true;
        // },
        // DOING(state, action) {
        //     state.doing = true;
        // },
        // CANCEL(state, action) {
        //     state.loading = false;
        //     state.doing = false;
        //     state.loadingOrderItems = false;
        // },
        // OBTAINED(state, action) {
        //     state.byShopIdById = {
        //         [action.payload.shopId]: {
        //             ...state.byShopIdById[action.payload.shopId],
        //             ...action.payload.byId,
        //         },
        //     };
        // },
        LOADING_PAYMENTS(state, action) {
            state.loading = true;
        },
        LOADING_ORDER_PAYMENTS(state, action) {
            state.loadingOrderPayments = true;
        },
        OBTAINED_ORDER_PAYMENTS(state, action) {
            state.orderPaymentsById = {
                ...state.orderPaymentsById,
                ...action.payload,
            };
            state.loadingOrderPayments = false;
        },
        OBTAINED_PAYMENTS(state, action) {
            state.paymentsById = action.payload;
        },
        UPDATE_PAYMENT(state, action) {
            state.paymentsById[action.payload.paymentId] = action.payload.data;
        },
        PAYMENT_INTENTS_AVAILABLE(state, action) {
            state.intentsById = { ...state.intentsById, ...action.payload };
        },
        PAYMENT_INTENT_AVAILABLE(state, action) {
            state.intentsById = { ...state.intentsById, [action.paymentId] : { ...(state.intentsById[action.paymentId] || {}), ...action.payload.intentsById } };
        }
    },
});

export const reducer = slice.reducer;
export default slice;

// Action Creators
export const getOrderPayments = (orderId) => async (dispatch) => {
    dispatch(slice.actions.LOADING_ORDER_PAYMENTS());
    let all = [];
    try {
        all = await paymentsService.getOrderPayments(orderId);
    } catch (error) {
        console.error(error);
    }
    const byId = {};
    all.forEach((orderPayment) => {
        byId[orderPayment.id] = orderPayment;
    });
    dispatch(slice.actions.OBTAINED_ORDER_PAYMENTS({ [orderId]: byId }));
};

export const getAll = () => async (dispatch) => {
    dispatch(slice.actions.LOADING_PAYMENTS());
    const all = await paymentsService.getAll();
    const byId = {};
    all.forEach((payment) => {
        byId[payment.id] = payment;
    });
    dispatch(slice.actions.OBTAINED_PAYMENTS(byId));
};

export const getLastPaymentIntent = (paymentId) => async (
    dispatch,
    getState,
) => {
    // dispatch(slice.actions.LOADING_ORDER_PAYMENTS());
    try {
        const lastPaymentIntent = await paymentsService.getLastPaymentIntent(
            paymentId,
        );
        const { payments: { paymentsById } } = getState();
        let updatePayment = {...paymentsById[paymentId]} || {};
        updatePayment.lastPaymentIntent = lastPaymentIntent[0] || {};
        dispatch(slice.actions.UPDATE_PAYMENT({ paymentId, data: updatePayment }));
        // dispatch(slice.actions.OBTAINED_ORDER_PAYMENTS(paymentsById));
    } catch (error) {
        console.error(error);
    }
};

export const getIntentsByPaymentId = (paymentId) => async (
    dispatch,
    getState,
) => {
    // dispatch(slice.actions.LOADING_ORDER_PAYMENTS());
    try {
        const intents = await paymentsService.getIntentsByPaymentId(
            paymentId,
        );
        const intentsById = {};
        intents.forEach((intent) => intentsById[intent.id] = intent);
        dispatch(slice.actions.PAYMENT_INTENTS_AVAILABLE({ [paymentId]: intentsById }));
    } catch (error) {
        console.error(error);
    }
};

export const createIntent = (paymentId, paymentMethod, amount) => async (
    dispatch,
    getState,
) => {
    // dispatch(slice.actions.LOADING_ORDER_PAYMENTS());
    try {
        
        const intent = await paymentsService.createIntent(
            paymentId,
            paymentMethod,
            amount
        );
        const intentsById = {[intent.id]: intent};
        dispatch(slice.actions.PAYMENT_INTENT_AVAILABLE({ intentsById, paymentId }));
    } catch (error) {
        console.error(error);
    }
};
export const cancel = (paymentId) => async (
    dispatch,
    getState,
) => {
    // dispatch(slice.actions.LOADING_ORDER_PAYMENTS());
    try {
        const payment = await paymentsService.cancel(paymentId);
        dispatch(slice.actions.UPDATE_PAYMENT({ paymentId, data: payment }));
    } catch (error) {
        console.error(error);
    }
};
// export const get = (orderId) => async (dispatch, getState) => {
//     const {
//         shops: { currentShop },
//     } = getState();
//     dispatch(slice.actions.LOADING());
//     const order = await ordersService.get(orderId);
//     const byId = {};
//     byId[orderId] = order;
//     // TODO un profile puede tener pedidos en varias tiendas, con lo que el currentShop pueder ser distinto al shop del pedido
//     dispatch(slice.actions.OBTAINED({ shopId: order.shopId, byId }));
//     dispatch(slice.actions.CANCEL());
// };

// export const select = (id) => async (dispatch) => {
//     dispatch(slice.actions.SELECT(id));
// };
