import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Breadcrumbs,
    Grid,
    Link,
    Typography,
    makeStyles,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import {
//     PlusCircle as PlusCircleIcon,
//     Download as DownloadIcon,
//     Upload as UploadIcon,
// } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { capitalize, strings } from 'src/utils/strings';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 10,
        paddingLeft: 25,
    },
    action: {
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const BreadcrumbsBar = (props) => {
    const { staticContext, className, title, path, ...rest } = props;
    const classes = useStyles();

    const renderBreadcrumbsLinks = () => {
        // TODO: Si se actualizan las rutas a / , no hará falta eliminar /app
        // y tampoco será necesaria la variable rootPath
        const rootPath = '/app';
        const links = [];
        if (!path) return null;
        const paths = path
            .replace('/app', '')
            .substring(1)
            .split('/');
        let pathLinks = '';
        // let topPath = '';
        paths.map((item, index) => {
            // if (item && item.charAt(0) !== ':') {
            if (item) {
                if (item.charAt(0) !== ':') {
                    if (index === paths.length - 1) {
                        if (item.charAt(0) !== ':') {
                            // topPath = item.charAt(0).toUpperCase() + item.slice(1);
                        }
                    } else {
                        pathLinks += `/${item}`;
                        links.push({
                            title: item.charAt(0).toUpperCase() + item.slice(1),
                            url: rootPath + pathLinks,
                        });
                        // topPath = item.charAt(0).toUpperCase() + item.slice(1);
                    }
                }
            }
            return null;
        });
        return (
            <Grid
                // className={classes.root}
                container
                justifyContent="space-between"
                spacing={3}>
                <Grid item>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">
                        {links.map((link, index) => {
                            return (
                                <Link
                                    key={`breadcrumb-${link.title}`}
                                    variant="body1"
                                    color="inherit"
                                    to={link.url}
                                    component={RouterLink}>
                                    {capitalize(
                                        strings(link.title.toLowerCase()),
                                    )}
                                </Link>
                            );
                        })}
                        {/* <Typography variant="body1" color="textPrimary">
                            {topPath}
                        </Typography> */}
                    </Breadcrumbs>
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid
            className={clsx(classes.root, className)}
            container
            justifyContent="space-between"
            spacing={3}
            {...rest}>
            <Grid item>
                <Typography variant="h3" color="textPrimary">
                    {title}
                </Typography>
                {renderBreadcrumbsLinks()}
            </Grid>
        </Grid>
    );
};

BreadcrumbsBar.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    renderBreadcrumbsLinks: PropTypes.func,
    path: PropTypes.string,
};

export default withRouter(BreadcrumbsBar);
